import PropTypes from "prop-types";
import React from "react";
import { Container, Row } from "shards-react";

const MainFooter = ({ contained, menuItems, copyright, linkStyle }) => (
  <footer className="main-footer p-4 px-3" style={{bottom:'0',backgroundColor:'#613dd4',opacity:1, zIndex:9999}}>
    <Container fluid={contained}>
      <Row>
        <span className="copyright my-auto lg:mr-2">{copyright}</span>
        <span className="text-center link-container">
        {menuItems.map((item, idx) => (
          item.to ?  <a key={idx} style={linkStyle} rel="noopener noreferrer" href={item.to} target="_blank">{item.title}</a> : <span key={idx} className="copyright my-auto mr-2">{item.title}</span>
        ))}
        </span>
      </Row>
    </Container>
  </footer>
);


MainFooter.propTypes = {
  contained: PropTypes.bool,
  menuItems: PropTypes.array,
  copyright: PropTypes.string
};

const year = new Date().getFullYear();

MainFooter.defaultProps = {
  contained: false,
  copyright: `© Murmuratto ${year}`,
  menuItems: [
    {
      title: 'Privacy Policy',
      to: "https://murmuratto.com/customer-privacy-policy/"
    },
    {
      title: '4.2.2.9c',
      to: ''
    }
  ],
  linkStyle: {
    marginRight: 40,
    color: 'white',
  }
};

export default MainFooter;
