import React, { Component } from "react";

import Parse from 'parse';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import {
    Card,
    CardBody,
    Col,
    Form,
    ListGroup,
    ListGroupItem,
    Row
} from "shards-react";
import { fetchAllCategories, fetchCommittees, fetchDepartments, fetchTypes } from "../services/tableService";
import { authUser } from "../services/userService";

class IdeaFilterManagerLite extends Component {
    constructor(props) {
        super(props);
        this.state = {
            /* initial state */
            data: [],
            departments: [],
            department: [],
            statusData: [],
            newItem: '',
            searchString: '',
            category: [],
            status: '',
            ideaType: [],
            ideaQuestions: [],
            deletedCategoryData: [],
            evalComms: [],
            evalComm: '',
            userComms: [],
            transferCommittee: false,
            categories: [],
            types: [],
            committess: [],
            viewAs: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({ newItem: event.target.value });
    }


    componentDidMount() {
        this.fetchFilterData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.reset !== this.props.reset) {
            if (this.props.reset == true) {
                this.setState({ evalComm: '', ideaType: '', department: '', category: '' });
            }
        }
    }

    fetchFilterData = async () => {
        const categories = await fetchAllCategories(true);
        const departments = await fetchDepartments();
        const types = await fetchTypes();
        const committess = await fetchCommittees();

        this.setState({
            categories,
            departments,
            types,
            committess
        });

    }


    getUserComms = async () => {
        this.setState({ userComms: [] }, () => {
            this.fetchNewData("EvaluationCommittee")
        })
    }

    fetchNewData(myClassName) {
        const className = myClassName;

        var ItemClass = Parse.Object.extend(className);
        var query = new Parse.Query(ItemClass);

        if (myClassName == "IdeaCategory" || myClassName == "DeletedIdeaCategory" || myClassName == "IdeaDepartment" || myClassName == "EvaluationCommittee") {
            query.findAll()
                .then((results) => {

                    if (myClassName == 'DeletedIdeaCategory') {
                        this.setState({
                            deletedCategoryData: results
                        });
                    } else if (myClassName == 'IdeaDepartment') {
                        this.setState({
                            departments: results
                        });
                    } else if (myClassName == 'EvaluationCommittee') {
                        const user = Parse.User.current()
                        if (results) {
                            const filteredCommittees = results.filter(committee => {
                                const members = committee.get('members');
                                if (members) {
                                    const userInCommittee = members.some(member => member && member.value.id === user.id);
                                    if (userInCommittee) return true;
                                }

                                if (committee.get('committeeLeader') === user.get('username')) return true;

                                return false;
                            });

                            if (filteredCommittees) {
                                this.setState({
                                    evalComms: filteredCommittees
                                });
                            } else {
                                this.setState({
                                    evalComms: []
                                });
                            }
                        } else {
                            this.setState({
                                evalComms: []
                            });
                        }
                    }
                    else {
                        this.setState({
                            data: results
                        });
                    }

                }, (error) => {
                    this.setState({
                        data: []
                    });
                    // The object was not retrieved successfully.
                    // error is a Parse.Error with an error code and message.
                });
        } else {
            query.find()
                .then((results) => {

                    this.setState({
                        statusData: results
                    });
                }, (error) => {
                    this.setState({
                        statusData: []
                    });
                    // The object was not retrieved successfully.
                    // error is a Parse.Error with an error code and message.
                });
        }
    }

    handleSubmit(event) {
        const className = this.props.className;
        const PropertyItem = Parse.Object.extend(className);
        const newItem = new PropertyItem();

        newItem.set("itemName", this.state.newItem);

        if (className == "IdeaCategory") {
            newItem.set("show", false);
        }
        newItem.set("customUpdatedAt", new Date());
        newItem.save()
            .then((item) => {
                // Execute any logic that should take place after the object is saved.
                this.fetchNewData();
                alert('¡La operacion fue exitosa!');
            }, (error) => {
                // Execute any logic that should take place if the save fails.
                // error is a Parse.Error with an error code and message.
                alert('Hubo un error en la operacion: ' + error.message);
            });
    }

    handleCategoryChange(event) {
        const newCat = event.label;
        this.props.onCategoryChange(newCat);
        this.setState({
            category: newCat
        });
    }

    handleMultiCategoryChange(event) {
        const newCat = event;
        this.props.onCategoryChange(newCat);
        this.setState({
            evalComm: { value: 'All', label: 'All' },
            category: newCat
        });
    }

    handleDepartmentChange(event) {
        const newDept = event.label;
        this.props.onDepartmentChange(newDept);

        this.setState({
            department: newDept
        });
    }

    handleMultiDepartmentChange(event) {
        const newDept = event;
        this.props.onDepartmentChange(newDept);

        this.setState({
            evalComm: { value: 'All', label: 'All' },
            department: newDept
        });
    }

    handleStatusChange(event) {


        var newStat = event.target.value == 'Sometidas' ? 'SOMETIDA' : event.target.value;

        this.props.onStatusChange(newStat);
        this.setState({
            status: newStat,
        });
    }

    handleTypeChange(event) {
        var newType = event.label == 'Todas' ? 'Todas' : event.label;

        this.props.onTypeChange(newType.toLowerCase());
        this.setState({
            ideaType: newType
        });
    }

    handleMultiTypeChange(event) {
        this.props.onTypeChange(event);
        this.setState({
            ideaType: event
        });
    }

    handleViewAsChange(event) {
        if(event)
         this.props.onViewAsChange(event);
        else
            this.props.onViewAsChange(null);
        
        this.setState({
            viewAs: event
        });
    }


    render() {
        const { category, ideaType, categories, departments, types, viewAs } = this.state;
        const { t } = this.props;
        const storageLanguage = localStorage.getItem('language') != null ? localStorage.getItem('language') : 'en';
        const currentUser = authUser();

        return (
            <Card small className="mb-3">
                <CardBody className="p-0">
                    <ListGroup flush>
                        <ListGroupItem className="p-3">
                            <Row>
                                <Col>
                                    <Form>
                                        <Row form>
                                            <Col md="12" className="form-group">
                                                <label htmlFor="feInputState" >{t("IDEA_MANAGE_CATS")}</label>

                                                <Select
                                                    isMulti
                                                    options={[
                                                        // { value: 'All Categories', label: 'All' },
                                                        ...categories.map((item, idx) => ({
                                                            value: item.id,
                                                            label: item.transcription[storageLanguage]
                                                        }))

                                                    ]}
                                                    onChange={this.handleMultiCategoryChange.bind(this)}
                                                    value={category}
                                                    placeholder={t("Select Categories")}
                                                />
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                                <Col>
                                    <Form>
                                        <Row form>
                                            <Col md="12" className="form-group">
                                                <label htmlFor="feInputState">{t('IDEA_MANAGE_DEPTS')}</label>

                                                <Select
                                                    isMulti
                                                    options={[
                                                        // { value: 'All Departments', label: 'All' },
                                                        ...departments.map((item, idx) => ({
                                                            value: item.id,
                                                            label: item.transcription[storageLanguage]
                                                        }))
                                                    ]}
                                                    onChange={this.handleMultiDepartmentChange.bind(this)}
                                                    value={this.state.department}
                                                    placeholder={t("Select Departments")}
                                                />
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>

                                <Col>
                                    <Form>
                                        <label htmlFor="feInputState">{t("IDEA_MANAGE_TYPES")}</label>

                                        <Select
                                            isMulti
                                            id="feInputState"
                                            options={[
                                                // { value: 'All Departments', label: 'All' },
                                                ...types.map((item, idx) => ({
                                                    value: item.id,
                                                    label: item.title.en
                                                }))
                                            ]}
                                            onChange={this.handleMultiTypeChange.bind(this)}
                                            value={ideaType}
                                            placeholder={t("Select Type")}
                                        />

                                    </Form>
                                </Col>

                                 <Col>
                                    <Form>
                                        <label htmlFor="feInputState">{t("View As")}</label>

                                        <Select
                                            isMulti
                                            id="feInputState"
                                            options={currentUser.implementDashboardViewAs.map((item, idx) => ({
                                                value: item,
                                                label: item
                                            }))}
                                            onChange={this.handleViewAsChange.bind(this)}
                                            value={viewAs}
                                            placeholder={t("View As")}
                                        />

                                    </Form>
                                </Col> 

                            </Row>
                        </ListGroupItem>
                    </ListGroup>
                </CardBody>
            </Card>

        );
    }
}


export default withTranslation()(IdeaFilterManagerLite);