import React, { useState } from "react";
import CompletedIdeaFormNew from "../../views/CompletedIdeaFormNew";
import DuplicateForm from "../common/DuplicateForm";
import ImplementedSuccess from "../ImplementedSucces";

const FormSwapper = ({ showA3, currIdea, updateCurrIdea, handleCloseModal, handleCloseA3, form, closeForms, succesScreen = false }) => {
    const [success, setSuccess] = useState(false)
    const [comments, setComments] = useState(null)
    const [file, setFile] = useState(null)

    const updateData = (com, fileId) => {         
        setComments(com)
        setFile(fileId)
        setSuccess(true)
    }

    const onBack = () => {
        closeForms(1)
    }

    return success ?
        (
            <ImplementedSuccess onBack={onBack} comments={comments || currIdea.implement.comments} file={currIdea.implement ? currIdea.implement.attachment : file} form={form} currIdea={currIdea} />
        ) : (
            <div>
                {/* {form !== "implemented" &&
                    <Row noGutters className="page-header pt-4 pb-4">
                        <PageTitle
                            title={'Overview'}
                            subtitle={form}
                            className="text-sm-left mb-3"
                        />
                    </Row>
                } */}
                {(form === "Implemented" || showA3)  && (
                    <div>
                        <CompletedIdeaFormNew
                            currIdea={currIdea}
                            updateCurrIdea={() => updateCurrIdea()}
                            closeModal={() => handleCloseModal()}
                            closeA3={() => handleCloseA3()}
                            closeForms={() => closeForms()}
                            fromImplement={true}
                            stage="completed"
                        />
                    </div>
                )}
                {
                    (form === "Duplicate" || form === "Not Implemented") && <DuplicateForm form={form} currIdea={currIdea} closeForms={() => closeForms()} updateData={updateData} />
                }
            </div>
        )
}

export default FormSwapper