import Parse from 'parse';
import React from 'react';
import ReactLoading from 'react-loading';
import { Col, FormInput, FormTextarea, Modal, ModalBody, ModalHeader, Row } from "shards-react";
import Swal from 'sweetalert2';
import { ReactComponent as AcceptIcon } from "../../images/accept_button.svg";
import { ReactComponent as AcceptIcon2 } from "../../images/accept_button2.svg";
import { ReactComponent as NextIcon } from "../../images/clean_next.svg";
import { ReactComponent as NextIconValid } from "../../images/clean_next_selected.svg";
import { fetchQuestions } from '../../services/tableService.js';

import { submitOpportunity } from '../../services/ideaService.js';
import CategoryBasicSelect from '../common/CategoryBasicSelect';
import DepartmentSelect from '../common/DepartmentSelect';
import CustomFileWithProgressUpload from "../components-overview/CustomFileWithProgressUpload";


class MergeIdeasModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: this.props.text,
      idea: this.props.idea,
      similarOpportunity: this.props.similarOpportunity,
      responseInfo: false,
      open: false,
      pmoAligned: '',
      canSubmit: false,
      canContinue: false,
      modalStage: 1,
      department: '',
      category: '',
      type: '',
      ideaQuestions: [],
      filterQuestions: [],
      filteredQuestions: [],
      answers: [],
      ideaTitle: '',
      loading: false,
      hasTeam: false,
      teamName: '',
      selectedEmployees: [],
      expectedReturn: 0,
      file: null,
      progress: 100,
    };
  }

  componentDidMount() {
    this.fetchQuestions();
  }

  componentDidUpdate(prevProps, prevState) {
    const currentStatus = prevProps.isOpen;
    const nextStatus = this.props.isOpen;

    if (currentStatus !== nextStatus) {
      this.setState({ open: this.props.isOpen });
    }
    if (prevState.modalStage !== this.state.modalStage) {
      this.validateForm();
    }
    
  }

  async fetchQuestions() {
    try {
      const questions = await fetchQuestions();
      this.setState({ ideaQuestions: questions });
    } catch (error) {
      console.error('Error fetching questions:', error);
    }
  }

  toggle = () => {
    this.props.closeModal();
  }

  toggleInfo = () => {
    this.setState({
      responseInfo: !this.state.responseInfo
    });
  }

  onTitleChange = (event) => {
    const title = event.target.value;
    this.setState({ ideaTitle: title }, this.validateForm);
  }

  onTextChange = (event) => {
    const text = event.target.value;
    this.setState({ message: text }, this.validateForm);
  }

  handleInputChange = (e, question, index) => {
    const { value } = e.target;

    const answerValue = value || ""; 
    const questionText = question.transcription && question.transcription.en ? question.transcription.en : "";
    const isRequired = question.required != null ? question.required : false;

    let answers = [...this.state.answers];
    answers[index] = {
      question: questionText,
      answer: answerValue,
      required: isRequired
    };

    this.setState({ answers }, this.validateForm);
  }


  updateInfo = () => {
    if (this.state.message.length > 0) {
      this.props.changeText(this.state.message);
      this.props.changeSelection(this.state.pmoAligned);
    } else {
      alert('Your request cannot be empty.');
    }
  }

  closeModal = () => {
    this.setState({ rfiMessage: '' });
    this.props.closeModal();
  }

  changeModalStage = () => {
    const { modalStage } = this.state;
    if (modalStage === 1) {
      this.setState({ modalStage: 2 }, this.validateForm);
    } else if (modalStage === 2) {
      this.setState({ modalStage: 3, canSubmit: true, canContinue: false }, this.validateForm);
    } else if (modalStage === 3) {
      this.setState({ modalStage: 1, canSubmit: true }, this.validateForm);
    }
  }

  setDepartment = (res, i) => {
    this.setState({ department: res }, this.validateForm);
  }

  setCategory = (res) => {
    const { ideaQuestions } = this.state;
    var filteredData = ideaQuestions.filter(item => item.categoryId === res.value);
    
    this.setState(prevState => ({
      category: res,
      filteredQuestions: filteredData,
      selectedAttributes: {
        ...prevState.selectedAttributes,
        category: res.label
      }
    }), this.validateForm);
  }

  setType = (res) => {
    const { ideaQuestions } = this.state;
    var filteredData = ideaQuestions.filter(item => item.typeId === res.value);
    this.setState(prevState => ({
      ideaType: res.label,
      type: res,
      filteredQuestions: filteredData,
      selectedAttributes: {
        ...prevState.selectedAttributes,
        type: res.label
      }
    }), this.validateForm);
  }

  

validateForm = () => {
  const { modalStage, ideaTitle, message, answers,  department, category, filteredQuestions, progress } = this.state;
  let isTitleValid, isDescriptionValid, areAnswersValid;

  switch (modalStage) {
    case 1:
      isTitleValid = ideaTitle.trim() !== '';
      isDescriptionValid = message.trim() !== '';
      this.setState({ canContinue: isTitleValid && isDescriptionValid && (progress === 100) });
      break;
    case 2:
      this.setState({ canContinue: department && category });
      break;
    case 3:
      
      areAnswersValid = filteredQuestions.every((question, index) => {
        const answer = answers[index];
        if (question.required) {
          return answer && answer.answer.trim() !== '';
        }
        return true;
      });

      this.setState({ canContinue: areAnswersValid });

      break;
    default:
      this.setState({ canContinue: false });
      break;
  }
}

  async getIdeaCount() {
    const Idea = Parse.Object.extend("Idea");
    const query = new Parse.Query(Idea);

    query.descending("num");

    query.limit(1);

    const highestIdea = await query.first();

    if (highestIdea) {
      return highestIdea.get("num") + 1;
    } else {
      return 1;
    }
  }


  async saveIdea() {
    const {
      file,
      answers,
      ideaTitle,
      hasTeam,
      expectedReturn,
      selectedEmployees,
      category,
      type,
      department,
      idea,
      message,
      similarOpportunity,
    } = this.state;
  
    this.setState({ loading: true, canExit: false });
   
  

    // Prepare the opportunity data
    const opportunityData = {
      title: ideaTitle,
      description: message,
      categoryId: category.value,
      departmentId: department.value,
      typeId: type.value,
      fileId: file ? file.fileId : null,
      team: hasTeam ? selectedEmployees : [],
      expectedReturn: parseFloat(expectedReturn),
      filterAnswer: answers,
      answer: answers,
      currentOpportunityId: idea.id,
      similarOpportunityId: similarOpportunity.id
      
    };
  
    try {
      await submitOpportunity(opportunityData);
      this.props.closeModal();

      Swal.fire({
        title: 'Success',
        text: 'The opportunity has been submitted',
        icon: 'success',
        confirmButtonText: 'OK'
      }).then(() => {
          window.location.href = '/evaluate';
      });
    } catch (error) {
      console.error('Failed to submit opportunity, with error code: ' + error.message);
    }
  }
  
  render() {
    const { open, canSubmit, canContinue, modalStage, department, category, loading} = this.state;
    const { idea, similarOpportunity } = this.props;

    return (
      <Modal open={open} className="modal1000" centered toggle={() => this.toggle()} >
        
        <ModalHeader>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>Merge</div> 
        <span 
          style={{
            cursor: 'pointer',
            fontWeight: 'bold',
            fontSize: '24px',
          }}
          onClick={this.closeModal}
        >
          &times;
        </span>
      </div>
      </ModalHeader>
       
        <ModalBody style={{ maxHeight: '500px', overflowY: 'auto' }}>
          {loading ? (
            <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '30em' }}>
              <ReactLoading type={'spokes'} color={'#633FDA'} width={80} height={80} />
            </div>
          ) : (
            <>
              {modalStage === 1 && <Row className="mb-4">
                <Col>
                  <Row className="mb-4">
                    <Col>
                      <h6 className="mb-4" style={{ fontWeight: 500, color: 'red' }}>Current Opportunity</h6>
                      <label htmlFor="firstName" className="georgia">Title</label>
                      <FormInput value={idea.title} disabled></FormInput>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label htmlFor="firstName" className="georgia">Description</label>
                      <FormTextarea
                        style={{ minHeight: "80px" }}
                        id="userBio"
                        placeholder={'Description...'}
                        value={idea.submittal.description}
                        disabled
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg="1" className="mx-auto">
                  <div className="mx-auto" style={{ height: '100%', width: 1, backgroundColor: '#BABABA' }}></div>
                </Col>
                <Col>
                  <Row>
                    <Col className="mb-4">
                      <h6 className="mb-4" style={{ fontWeight: 500, color: 'red' }}>Similar Opportunity</h6>
                      <label htmlFor="firstName" className="georgia">Title</label>
                      <FormInput value={similarOpportunity.title} disabled></FormInput>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label htmlFor="firstName" className="georgia">Description</label>
                      <FormTextarea
                        style={{ minHeight: "80px" }}
                        id="userBio"
                        placeholder={'Description...'}
                        value={similarOpportunity.submittal.description}
                        disabled
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg="1" className="mx-auto">
                  <div className="mx-auto" style={{ height: '100%', width: 1, backgroundColor: '#BABABA' }}></div>
                </Col>
                <Col>
                  <Row className="mb-4">
                    <Col>
                      <h6 className="mb-4" style={{ fontWeight: 500, color: 'red' }}>Merged Opportunity</h6>
                      <label htmlFor="firstName" className="georgia">Title</label>
                      <FormInput onChange={(event) => this.onTitleChange(event)}></FormInput>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label htmlFor="firstName" className="georgia">Description</label>
                      <FormTextarea
                        style={{ minHeight: "80px", marginBottom: 24 }}
                        id="userBio"
                        placeholder={'Description...'}
                        value={this.state.message}
                        onChange={(event) => this.onTextChange(event)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label htmlFor="firstName" className="georgia">Add an attachment</label>
                      <CustomFileWithProgressUpload
                            onProgressUpdate={(progress) => {
                              this.setState({ progress: progress }, this.validateForm());
                            }}
                            onFileUpload={(file) => {
                              this.setState({ file, progress: 100 }, this.validateForm());
                            }}
                            maximumSizeFile={10} />
                    </Col>
                  </Row>
                </Col>
              </Row>}

              {modalStage === 2 && <Row className="mb-4">
                <Col>
                  <Row className="mb-4">
                    <Col>
                      <h6 className="mb-4" style={{ fontWeight: 500, color: 'red' }}>Current Opportunity</h6>
                      <label htmlFor="firstName" className="georgia">Department</label>
                      <FormInput value={idea.department} disabled></FormInput>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col>
                      <label htmlFor="firstName" className="georgia">Category</label>
                      <FormInput value={idea.category.name} disabled></FormInput>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label htmlFor="firstName" className="georgia">Type</label>
                      <FormInput value={idea.type} disabled></FormInput>
                    </Col>
                  </Row>
                </Col>
                <Col lg="1" className="mx-auto">
                  <div className="mx-auto" style={{ height: '100%', width: 1, backgroundColor: '#BABABA' }}></div>
                </Col>
                <Col>
                  <Row className="mb-4">
                    <Col>
                      <h6 className="mb-4" style={{ fontWeight: 500, color: 'red' }}>Similar Opportunity</h6>
                      <label htmlFor="firstName" className="georgia">Department</label>
                      <FormInput value={similarOpportunity.department} disabled></FormInput>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col>
                      <label htmlFor="firstName" className="georgia">Category</label>
                      <FormInput value={similarOpportunity.category.name} disabled></FormInput>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label htmlFor="firstName" className="georgia">Type</label>
                      <FormInput value={similarOpportunity.type} disabled></FormInput>
                    </Col>
                  </Row>
                </Col>
                <Col lg="1" className="mx-auto">
                  <div className="mx-auto" style={{ height: '100%', width: 1, backgroundColor: '#BABABA' }}></div>
                </Col>
                <Col>
                  <Row className="mb-3">
                    <Col>
                      <h6 className="mb-4" style={{ fontWeight: 500, color: 'red' }}>Merged Opportunity</h6>
                      <label htmlFor="firstName" className="georgia">Department</label>
                      <DepartmentSelect className="insideFont" evalType={'execution'} setResponsible={(res, idx) => this.setDepartment(res, idx)} selectedVal={department} />
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col>
                      <label htmlFor="firstName" className="georgia">Category</label>
                      <CategoryBasicSelect className="insideFont" evalType={"execution"} setResponsible={(res, idx) => this.setCategory(res, idx)} selectedVal={category} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label htmlFor="firstName" className="georgia">Type</label>
                      <CategoryBasicSelect setResponsible={(res, idx) => this.setType(res, idx)} />
                    </Col>
                  </Row>
                </Col>
              </Row>}

              {modalStage === 3 && <Row className="mb-4">
                <Col>
                  <h6 className="mb-4" style={{ fontWeight: 500, color: 'red' }}>Current Opportunity</h6>
                  {idea.submittal.questions.map((question, index) => {
                    if(!question) return null;
                    const prefix = 'Q' + (index + 1) + ': ';
                    const myQuestion = prefix + question["question"];
                    return (
                      <Row className="mt-4" key={index}>
                        <Col md="12">
                          <label className="georgia">{myQuestion}</label>
                          <h6 style={{ fontWeight: 500, color: '#303030' }}>{question["answer"]}</h6>
                        </Col>
                      </Row>
                    );
                  })}
                </Col>
                <Col lg="1" className="mx-auto">
                  <div className="mx-auto" style={{ height: '100%', width: 1, backgroundColor: '#BABABA' }}></div>
                </Col>
                <Col>
                  <h6 className="mb-4" style={{ fontWeight: 500, color: 'red' }}>Similar Opportunity</h6>
                  {similarOpportunity.submittal.questions.map((question, index) => {
                    const prefix = 'Q' + (index + 1) + ': ';
                    const myQuestion = prefix + question["question"];
                    const required = question["required"];
                    return (
                      <Row className="mt-4" key={index}>
                        <Col md="12">
                          <label className="georgia">{myQuestion}</label>
                          <h6 style={{ fontWeight: 500, color: '#303030' }}>{question["answer"]}</h6>
                        </Col>
                      </Row>
                    );
                  })}
                </Col>
                <Col lg="1" className="mx-auto">
                  <div className="mx-auto" style={{ height: '100%', width: 1, backgroundColor: '#BABABA' }}></div>
                </Col>
                <Col>
                  <h6 className="mb-4" style={{ fontWeight: 500, color: 'red' }}>Merged Opportunity</h6>
                  {[...this.state.filteredQuestions].map((question, index) => {
                    const myQuestion = question.transcription.en;
                    const required = question.required;
                    return (
                      <Row className="mt-4" key={index}>
                        <Col md="12">
                          <label className="georgia">{myQuestion} {required ? '*' : ''}</label>
                          <FormInput placeholder={question["answer"]} onChange={(e) => this.handleInputChange(e, question, index)}></FormInput>
                        </Col>
                      </Row>
                    );
                  })}
                </Col>
              </Row>}

              <Row className="mt-4">
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                  <div className="ml-auto" style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className='mr-2'>
                      {canSubmit && canContinue && <AcceptIcon2 className="functionalButton ml-auto" style={{ height: 34, width: 34 }} onClick={() => this.saveIdea()} />}
                      {canSubmit && !canContinue && <AcceptIcon className=" ml-auto" style={{ height: 34, width: 34 }} />}
                      {!canSubmit && !canContinue && <NextIcon className="functionalButton ml-auto" style={{ minWidth: 34, maxWidth: 34 }} />}
                      {!canSubmit && canContinue && <NextIconValid className="functionalButton ml-auto" style={{ minWidth: 34, maxWidth: 34 }} onClick={() => this.changeModalStage()} />}
                    </div>
                  </div>
                </div>
              </Row>
            </>
          )}
        </ModalBody>
      </Modal>
    );
  }
}

export default MergeIdeasModal;
