import React from "react";
import { withTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormInput,
  FormSelect,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "shards-react";
import { fetchOpportunityById } from "../services/evaluateService";
import { getImplementOpportunitiesWithFilters, getInbox } from "../services/implementService";
import SingleProgressChart from "./analytics/GoalsOverview/SingleProgressChart";
import ResponsiveTable from "./responsive-table/ResponsiveTable";

class ImplementDashboardTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ideas: [],
      isLoading: false,
      evalCriteria: [],
      filteredIdeas: [],
      filteredData: [],
      title: '',
      evalComms: [],
      includeTcComm: false,
      transferCommResults: [],
      pageSizeOptions: [5, 10, 15, 20, 25, 30],
      pageSize: 5,
      search: ''
    }

    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.handleFilterSearch = this.handleFilterSearch.bind(this);
  }

  componentDidMount() {
    this.fetchInboxFromService();
  }

  fetchInboxFromService = async () => {
    const { isLoading } = this.state;
    if (isLoading) return;

    this.setState({ isLoading: true });

    try {
      const inbox = await getInbox('dashboard');
      this.setState({
        ideas: inbox,
        filteredData: inbox,
      })
    } catch (error) {
      this.setState({ isLoading: false });
      console.error('Error fetching inbox:', error);
      alert('There was an error fetching inbox. Please try again later.');
    } finally {
      this.setState({ isLoading: false });
    }
  }
  componentDidUpdate(prevProps) {
    const { ideas, transferCommResults } = this.state;

    if (
      prevProps.category !== this.props.category ||
      prevProps.status !== this.props.status ||
      prevProps.ideaType !== this.props.ideaType ||
      prevProps.department !== this.props.department ||
      prevProps.viewAs !== this.props.viewAs
    ) {
      if (this.props.status === "Transfer Committee") {
        // Pass the parameters in the correct order
        this.filterIdeas(
          this.props.category,        // categories
          transferCommResults,        // ideas
          this.props.department,      // departments
          this.props.ideaType,        // ideaTypes
          true,                        // transferCommittee
          this.props.viewAs
        );
      } else {
        this.filterIdeas(
          this.props.category,        // categories
          ideas,                      // ideas
          this.props.department,      // departments
          this.props.ideaType,        // ideaTypes
          false,                       // transferCommittee
          this.props.viewAs
        );
      }
    }
  }


  arrayIncludesInObj = (arr, key, valueToCheck) => {
    return arr.some(value => value[key] === valueToCheck);
  }


  async filterIdeas(categories, ideas, departments, ideaTypes, transferCommittee, viewAs) {
    const { evalComm } = this.props;
    const { isLoading } = this.state;
    if (isLoading) return

    const categoryIds = (categories || []).map(category => category.value);
    const ideaTypeIds = (ideaTypes || []).map(ideaType => ideaType.value);
    const departmentIds = (departments || []).map(department => department.value);
      const viewAsIds = (viewAs || []).map(v => v.value);


    const filters = {
      categoriesIds: categoryIds,
      typesIds: ideaTypeIds,
      departmentsIds: departmentIds,
      committeesIds: transferCommittee ? evalComm : undefined,
      isTransferred: transferCommittee,
      viewAs: viewAsIds
    };

    this.setState({ isLoading: true });

    try {
      const filteredData = await getImplementOpportunitiesWithFilters(filters);
      this.setState({ filteredData, ideas: filteredData });
    } catch (error) {
      this.setState({ isLoading: false });
      console.error('Error filtering ideas:', error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  handleDuplicateClick = async (item) => {
    try {
      // Call fetchOpportunityById to retrieve the similar idea by its ID
      const similarIdea = await fetchOpportunityById(item.potentialDuplicates[0]);
      if (similarIdea) {
        // Pass the similar idea data to onViewIdeaPress with the duplicate flag

        this.props.onViewIdeaPress(item, true);
      } else {
        // If no similar idea found, pass with the duplicate flag set to false
        this.props.onViewIdeaPress(item, false);
      }
    } catch (error) {
      console.error("Error fetching similar idea:", error);
    }
  };

  fetchDuplicates = (idea) => {
    // const cachedShowDuplicateTab = localStorage.getItem('showDuplicateTab') === 'true';
    // if (!cachedShowDuplicateTab) {
    //   return { isDuplicate: false };
    // }

    // Si es una idea mergeada no muestra duplicate
    // if(idea.get('duplicateOriginalOpportunity') && idea.get('duplicateSimilarOpportunity'))
    //   return { isDuplicate: false };

    // // Si fue marcada como no duplicada no muestra más duplicate
    // if((idea.get('notDuplicates') && idea.get('notDuplicates').length > 0)){
    //   return { isDuplicate: false };
    // }

    const potentialDuplicates = idea.potentialDuplicates;
    if (potentialDuplicates && potentialDuplicates.length > 0) {
      return {
        isDuplicate: true,
        randomIdeaId: potentialDuplicates[0].id
      };
    }

    return { isDuplicate: false };
  };

  handlePageSizeChange(e) {
    this.setState({ pageSize: parseInt(e.target.value) });
  }

  handleFilterSearch(e) {
    const searchTerm = e.target.value.toLowerCase();
    this.setState({ search: searchTerm }, () => {
      const filteredData = this.state.ideas.filter(idea => {
        if(!idea) return false;
        if (idea.num === Number(searchTerm)) return true;

        return idea.title && idea.title.toLowerCase().includes(searchTerm) ||
          idea.category && idea.category.toLowerCase().includes(searchTerm) ||
          idea.status && idea.status.toLowerCase().includes(searchTerm)
      });
      this.setState({ filteredData });
    });
  }

  render() {
    const { pageSize, isLoading, pageSizeOptions, search } = this.state;
    const { t } = this.props;
    const columns = [
      {
        Header: t("IDEA_MANAGE_NUM"),
        accessor: "num",
        className: "text-center",
        maxWidth: 120,
      },
      {
        Header: t("IDEA_MANAGE_TITLE"),
        accessor: "title",
        className: "text-center",
        Cell: row => row.original.closed ?
          (<div>{row.value}<span style={{
            padding: "6px 8px",
            backgroundColor: "rgb(0, 123, 255)",
            color: "#fff",
            borderRadius: "30px",
            marginLeft: "5px",
            fontSize: "9.75px"
          }}>
            Closed
          </span></div>)
          :
          (<div>{row.value}</div>),
      },
      {
        Header: t("Owner"),
        maxWidth: 120,
        accessor: "owner",
        className: "text-center",
      },
      {
        Header: t("Days Open"),
        maxWidth: 120,
        accessor: "daysOpen",
        className: "text-center",
      },
      {
        Header: t("IDEA_MANAGE_PROG"),
        accessor: "progress",
        maxWidth: 150,
        className: "text-center",
        Cell: row => <SingleProgressChart idea={row.original.actions} />,
      },
      {
        Header: 'Action',
        accessor: "actions",
        className: "text-right",
        sortable: false,
        width: 80,
        Cell: row => (
          <Badge style={{ cursor: 'pointer' }} outline pill onClick={() => this.props.handleViewClick(row.original.actions)}>
            View
          </Badge>
        ),
      }
    ];


    const data = this.state.filteredData.map(idea => ({
      num: idea.num,
      title: idea.title,
      owner: idea.owner,
      daysOpen: idea.daysOpen,
      progress: idea.progress,
      actions: idea,
      a3Completed: idea.a3Completed,
      closed: idea.closed,

    }));



    return (
      <Card className={'transparent-responsive-card'}>
        <CardHeader className="p-0 responsive-table-filter">
          <Container fluid className="file-manager__filters border-bottom">
            <Row>
              <Col className="file-manager__filters__rows d-flex" md="6">
                <span>{t('SHOW')}</span>
                <FormSelect
                  size="sm"
                  value={pageSize}
                  onChange={this.handlePageSizeChange}
                >
                  {pageSizeOptions.map((size, idx) => (
                    <option key={idx} value={size}>
                      {size + ' ' + t('ROWS')}
                    </option>
                  ))}
                </FormSelect>
              </Col>

              <Col className="file-manager__filters__search d-flex" md="6">
                <InputGroup seamless size="sm" className="ml-auto">
                  <InputGroupAddon type="prepend">
                    <InputGroupText>
                      <i className="material-icons">search</i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput value={search} onChange={this.handleFilterSearch} />
                </InputGroup>
              </Col>
            </Row>
          </Container>
        </CardHeader>
        <CardBody className="p-0">
          <Container fluid className="px-0">
            {isLoading ? (
              <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '10em' }}>
                <ReactLoading type={'spokes'} color={'#633FDA'} width={80} height={80} />
              </div>
            ) : (
              <ResponsiveTable
                columns={columns}
                data={data}
                defaultPageSize={pageSize}
                pageSize={pageSize}
                pageSizeOptions={pageSizeOptions}
                showPageSizeOptions={false}
                resizable={false}
              />
            )}
          </Container>
        </CardBody>
      </Card>
    );
  }
}


export default withTranslation()(ImplementDashboardTable);
