import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';
import { withRouter } from 'react-router-dom';
import { Button, ButtonGroup, Col, Container, FormTextarea, Modal, ModalBody, ModalHeader, Row, } from "shards-react";
import Swal from 'sweetalert2';
import PageTitle from "../components/common/PageTitle";
import SubmitIdeaForm from "../components/common/SubmitIdeaForm";
import { ReactComponent as NextIcon } from "../images/NextIcon.svg";
import { ReactComponent as NextIconValid } from "../images/NextIconSelected.svg";
import { ReactComponent as PreviousIcon } from "../images/PreviousIcon.svg";
import { ReactComponent as AcceptIcon } from "../images/accept_button.svg";
import { ReactComponent as CancelIcon } from "../images/cancel_button.svg";
import { ReactComponent as DivisorBarIcon } from "../images/edited_divisor.svg";
import { ReactComponent as HomeIcon } from "../images/home.svg";
import { ReactComponent as SubmitIcon } from "../images/submit_new_new.svg";
import { ReactComponent as SubmitIconNosel } from "../images/submit_new_nosel.svg";
import { getUserInfo, postSurveyResponse } from '../services/meService';
import { authUser } from "../services/userService";
import colors from "../utils/colors";


function SubmitIdeaNew() {
  const { t } = useTranslation();
  const [username, setUsername] = useState('')
  const [title, setTitle] = useState('')
  const [ideaStage, setIdeaStage] = useState(0)
  const [canContinue, setCanContinue] = useState(false)
  const [canContinueTemp, setCanContinueTemp] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [surveyModalShow, setSurveyModalShow] = useState(false)
  const [organizationFeel, setOrganizationFeel] = useState('')
  const [selectedFilter, setSelectedFilter] = useState('')
  const [errorSubmitSurvey, setErrorSubmitSurvey] = useState(false);
  const [loadingSubmitSurvey, setLoadingSubmitSurvey] = useState(false);
  const [rating, setRating] = useState([false, false, false, false, false, false, false, false, false, false, false])
  const [ratingValue, setRatingValue] = useState(null)

  const handleCloseSurvey = () => {
    setSurveyModalShow(false);
  };


  useEffect(() => {
    if (ratingValue !== null)
      setErrorSubmitSurvey(false);

  }, [ratingValue]);

  const getUserName = async () => {
    const auth = await authUser();

    if (auth === null) {
      return;
    }

    const firstName = auth.firstName;
    setUsername(firstName)

    if (ideaStage === 0) {
      setTitle(`${t("WELCOME_BACK")} ${firstName}!`);
    }
  }

  const ActionButton = ({ active, icon: Icon, onClick, style, disabledMsg }) => {
    return (
      <Icon className="functionalButton ml-auto d-block" style={style} onClick={active ? onClick : () => alert(disabledMsg)} />
    );
  };

  const renderButtons = () => {
    const commonStyle = { minWidth: 140, maxWidth: 140 };

    if (ideaStage === 0) {
      return (
        <ActionButton
          active={canContinue}
          icon={canContinue ? NextIconValid : NextIcon}
          onClick={canContinue ? changeIdeaStage : alertMissingFields}
          style={commonStyle}
          disabledMsg="Please fill all required fields."
        />
      );
    } else if (ideaStage > 0 && ideaStage < 2) {
      return (
        <div className="d-flex justify-content-between">
          <PreviousIcon
            className="functionalButton mr-auto d-block"
            style={commonStyle}
            onClick={changeIdeaStageBack}
          />
          <ActionButton
            active={canContinue}
            icon={canContinue ? NextIconValid : NextIcon}
            onClick={canContinue ? changeIdeaStage : alertMissingFields}
            style={{ ...commonStyle, marginBottom: '4px' }}
            disabledMsg="Please answer all required questions."
          />
        </div>
      );
    } else if (ideaStage === 2) {
      return (
        <div className="d-flex justify-content-between">
          <PreviousIcon
            className="functionalButton mr-auto d-block"
            style={commonStyle}
            onClick={changeIdeaStageBack}
          />
          <ActionButton
            active={canContinue}
            icon={canContinue ? SubmitIcon : SubmitIconNosel}
            onClick={canContinue ? changeIdeaStage : alertMissingFields}
            style={{ ...commonStyle, marginBottom: '4px' }}
            disabledMsg="Please answer all required questions."
          />
        </div>
      );
    } else if (ideaStage === 3 && !isLoading) {
      return (
        <HomeIcon
          className="functionalButton ml-auto d-block"
          style={{ minWidth: 90, maxWidth: 90, marginBottom: '4px' }}
          onClick={changeIdeaStage}
        />
      );
    }
  };


  const getConfiguration = async () => {
    try {
      const configurationData = await getUserInfo();

      if (configurationData) {
        const showSurvey = configurationData.showSurvey;
        const lastSurveyDate = localStorage.getItem('lastSurveyDate');
        const currentDate = new Date().toISOString().split('T')[0];

        if (!lastSurveyDate || lastSurveyDate !== currentDate) {
          setSurveyModalShow(showSurvey);
          localStorage.setItem('lastSurveyDate', currentDate);
        } else {
          setSurveyModalShow(false);
        }
      }
    } catch (error) {
      console.error("Error fetching configuration or setting survey modal:", error);
    }
  };

  useEffect(() => {
    getUserName()
    getConfiguration()
  }, []);

  const changeIdeaStage = () => {
    const newStage = ideaStage + 1
    if (canContinueTemp !== null) {
      setTimeout(() => {
        setCanContinue(canContinueTemp)
        setCanContinueTemp(null)
      }, 10)
    } else {
      setTimeout(() => { setCanContinue(false) }, 10)
    }
    setIdeaStage(newStage)
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (newStage === 0) {
      setTitle(`${t("WELCOME_BACK")} ${username}!`);
    } else if (newStage === 1) {
      setTitle(t('IDEA_SELECT_IDEA_TYPE'))
    } else if (newStage === 2 || newStage === 3) {
      setTitle(t('Opportunity >') + ' ' + selectedFilter + t('Idea Details'))
    } else {
      setTitle(`${t("WELCOME_BACK")} ${username}!`);
      setIdeaStage(0)
      window.location.reload();
    }
  }


  const changeIdeaStageBack = () => {
    const newStage = ideaStage - 1

    if (canContinue !== null) {
      setTimeout(() => {
        setCanContinue(canContinue)
      }, 10)
    } else {
      setTimeout(() => {
        setCanContinue(false)
      }, 10)
    }
    setIdeaStage(newStage)

    if (newStage === 0) {
      setTitle(`${t("WELCOME_BACK")} ${username}!`);
    } else if (newStage === 1) {
      setTitle(t('IDEA_SELECT_IDEA_TYPE'))
    } else if (newStage === 2 || newStage === 3) {

      setTitle(t('Opportunity >') + ' ' + selectedFilter + t('Idea Details'))
    } else {
      setTitle(`${t("WELCOME_BACK")} ${username}!`);
      setIdeaStage(0)
      window.location.reload();
    }
  }

  const alertMissingFields = () => {
    const alertMessages = {
      0: 'Please select a category.',
      1: 'Please select opportunity type and department.',
      2: 'Please answer all required (*) question fields.'
    };

    const message = alertMessages[ideaStage];
    if (message) {
      alert(message);
    }
  }

  const submitSurvey = async () => {
    if (ratingValue === null) {
      setErrorSubmitSurvey(true);
      return;
    }

    setLoadingSubmitSurvey(true);

    try {
      // Send the survey response using the postSurveyResponse service
      await postSurveyResponse(ratingValue, organizationFeel);

      setLoadingSubmitSurvey(false);
      setSurveyModalShow(false);

      // Show success message
      await Swal.fire("Thanks for your time.", "Your survey has been submitted.", "success");
    } catch (error) {
      console.error("Error submitting survey:", error);

      setLoadingSubmitSurvey(false);

      // Show error message
      await Swal.fire("Error!", "An error occurred while submitting the survey.", "error");
    }
  };

  const changeSelected = (index) => {
    const ratingLength = 11;
    const rating = Array(ratingLength).fill(false);

    if (index >= 0 && index < ratingLength) {
      rating[index] = true;
    }

    setRatingValue(index);
    setRating(rating);
  }
  return (
    <Container fluid className="main-content-container px-4" style={{ backgroundColor: 'white' }}>
          <Row>
            <Col md="12" lg="12">
              <Row noGutters className="page-header pt-4 pb-2">
                <PageTitle title={title} subtitle={t("SUBMIT WORKFLOW")} className="text-sm-left mb-3" />
              </Row>
              {/* {
                ideaStage !== 0 ?
            : 
                
                     <Row noGutters className="page-header pt-4 pb-2">
              <PageTitle title={title} subtitle={t("SUBMIT WORKFLOW")} className="text-sm-left mb-3" />
            </Row>
                
                // <PageTitle style={{ maxWidth: "100%" }} title={title} subtitle={""} />
              } */}

            </Col>
          </Row>
          <Row>
            <Col>
              <DivisorBarIcon></DivisorBarIcon>
            </Col>
          </Row>

      {/* Divider */}
      <Row>
        <Col lg="12" className="m-auto">
          <SubmitIdeaForm
            currentStage={ideaStage}
            changeContinueStatus={(c) => { setTimeout(() => { setCanContinue(c) }, 15) }}
            isLoading={setIsLoading}
            changeSelectedFilter={(name) => setSelectedFilter(name)}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="12" className="mb-2 ml-auto">
          {renderButtons()}
        </Col>
      </Row>

      <Modal className="modalSurvey" open={surveyModalShow} centered backdrop toggle={() => setSurveyModalShow(false)}>
        <ModalHeader>
          <h5 className="m-auto" style={{ fontWeight: 500, color: '#303030' }}>eNPS Survey</h5>
        </ModalHeader>
        <ModalBody>
          {
            loadingSubmitSurvey ?
              <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '3em' }}>
                <ReactLoading type={'spokes'} color={'#633FDA'} width={40} height={40} />
              </div>
              :
              <>
                <Row>
                  <Col>
                    <label htmlFor="firstName" >{'On a scale from 0 to 10, how likely are you to recommend your company as a place to work?'}</label>
                    <ButtonGroup style={{ margin: '0 auto', textAlign: 'center', display: 'block' }}>
                      {rating.map((item, index) => {
                        return (
                          <Button key={index} onClick={() => changeSelected(index)} theme={item === true ? 'primary' : 'light'}>{index}</Button>
                        )
                      })}
                    </ButtonGroup>

                    {errorSubmitSurvey && <small style={{ fontSize: 12, textAlign: 'center', display: 'block', color: 'red', marginTop: '10px' }}>Please select a number from 0 to 10 to indicate your recommendation level.</small>}
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <label htmlFor="firstName" >Why? <small style={{ fontSize: 12 }}>(Optional)</small></label>
                    <FormTextarea
                      style={{ minHeight: "80px" }}
                      id="comments"
                      placeholder={'Type your response here.'}
                      value={organizationFeel}
                      onChange={(event) => setOrganizationFeel(event.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <small style={{ fontSize: 12 }}>All collected responses are analyzed by Murmuratto without using your identity.</small>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                    <div className="ml-auto" style={{ display: 'flex', flexDirection: 'row' }}>
                      <div className='mr-2'>
                        <AcceptIcon className="functionalButton ml-auto" style={{ height: 34, width: 34, cursor: 'pointer' }} onClick={submitSurvey}></AcceptIcon>
                      </div>
                      <div>
                        <CancelIcon className="functionalButton ml-auto" style={{ height: 34, width: 34, cursor: 'pointer' }} onClick={handleCloseSurvey}></CancelIcon>
                      </div>
                    </div>
                  </div>
                </Row>
              </>
          }
        </ModalBody>
      </Modal>
    </Container>
  )
}

SubmitIdeaNew.propTypes = {
  /**
   * The small stats data.
   */
  smallStats: PropTypes.array
};

SubmitIdeaNew.defaultProps = {
  signedIn: true,
  smallStats: [
    {
      label: "Ideas Submitted",
      value: "18",
      percentage: "12.4%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.primary.toRGBA(0.1),
          borderColor: colors.primary.toRGBA(),
          data: [9, 3, 3, 9, 9]
        }
      ]
    },
    {
      label: "Ideas Accepted",
      value: "7",
      percentage: "7.21%",
      increase: false,
      chartLabels: [null, null, null, null, null],
      decrease: true,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.success.toRGBA(0.1),
          borderColor: colors.success.toRGBA(),
          data: [3.9, 4, 4, 9, 4]
        }
      ]
    },
    {
      label: "Ideas in Progress",
      value: "4",
      percentage: "3.71%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.warning.toRGBA(0.1),
          borderColor: colors.warning.toRGBA(),
          data: [6, 6, 9, 3, 3]
        }
      ]
    },
  ]
};

export default withTranslation()(withRouter(SubmitIdeaNew));