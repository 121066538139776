import React, { useEffect, useState } from "react";
import { Card, CardBody, FormTextarea } from "shards-react";
import { ReactComponent as PreviousIcon } from "../../images/PreviousIcon.svg";
import { ReactComponent as SubmitIcon } from "../../images/submit_new_new.svg";
import { ReactComponent as SubmitIconNosel } from "../../images/submit_new_nosel.svg";
import { sendDuplicate } from "../../services/implementService";
import OriginalIdeaInfoCompleted from "../administration/OriginalIdeaInfoCompleted";
import CustomFileWithProgressUpload from "../components-overview/CustomFileWithProgressUpload";
import ResponseRequired from "./ResponseRequired";


const DuplicateForm = ({ currIdea, closeForms, form, updateData }) => {
    const [showIdeaInformation, setShowIdeaInformation] = useState(false)
    const [showIdeaA3, setShowIdeaA3] = useState(false)
    const [openVerification, setOpenVerification] = useState(false)
    const [openRecognition, setOpenRecognition] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [description, setDescription] = useState('')
    const [progress, setProgress] = useState(0);
    const [file, setFile] = useState(null)
    const [valid, setValid] = useState(false)

    const handleSubmit = async () => {
        const action = form === "Duplicate" ? "duplicate" : "notImplemented"
        setIsLoading(true);

        const data = {
            additionalDetails: description,
            fileId: file ? file.fileId : null,
        };

        try {
            const res = await sendDuplicate(currIdea.id, data, action);
            updateData(description, res.attachment)
            window.location.href = "/implement"
        } catch (error) {
            alert('Failed to save results: ' + error.message);
        } finally {
            setIsLoading(false)
        } 
    }

    const validateForm = () => {
        if ((progress === 0 || progress === 100) && description.length > 1) {
            setValid(true)
        } else {
            setValid(false)
        }
    }

    useEffect(() => {
        validateForm()
        
    }, [progress, description])

    return (
        <div>
            <OriginalIdeaInfoCompleted view={undefined}
                currIdea={currIdea}
                openIdea={() => setShowIdeaInformation(true)}
                key={Math.random()}
                openIdeaA3={() => setShowIdeaA3(true)}
                openVerification={() => setOpenVerification(true)}
                openRecognition={() => setOpenRecognition(true)}
                id={Math.random()}
                open={true}
                shouldReset={false}
                updateUsers={() => { }} 
                user={{}}
                updateData={() => updateData()}
                stage={"completed"}
            ></OriginalIdeaInfoCompleted>

            
            <h3 style={{ width: "100%", textAlign: "center", color: "#000", paddingTop: "50px", display: "inline-flex", justifyContent: "center", gap: "4px" }}>Implementation Analysis: <p style={{ textTransform: "capitalize" }}>{form === "Duplicate" ? " Duplicated" : " Not Implemented"}</p></h3>
            <Card className="h-100" style={{ minHeight: "250px" }}>
                <CardBody>
                    <label style={{ marginBottom: "20px" }} htmlFor="question"><strong>{'Please provide additional details to explain why this opportunity was not implemented.*'} </strong></label>
                    <FormTextarea
                        style={{ minHeight: "90px", marginBottom: "10px" }}
                        id="userBio"
                        placeholder={"Type Response Here."}
                        value={description}
                        onChange={(event) => setDescription(event.target.value)}
                        inactive
                    />
                    <br />

                    <div style={{ marginBottom: "16px" }}>
                        <label htmlFor="question"><strong>{'Add an attachment'}: </strong></label>
                        <span>
                            <CustomFileWithProgressUpload maximumSizeFile={10}
                                onProgressUpdate={(currentProgress) => {
                                    setProgress(currentProgress)
                                }}
                                onFileUpload={(file) => {
                                    setFile(file)
                                }}
                            />
                        </span>
                    </div>
                    <ResponseRequired />
                </CardBody>
            </Card>
            <div style={{ marginTop: "30px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <button
                    onClick={() => closeForms()}
                    style={{ background: "none", border: "none" }}>
                    <PreviousIcon
                        className="functionalButton mr-auto d-block"
                        style={{ minWidth: 140, maxWidth: 140 }}
                    />
                </button>
                <button
                    onClick={() => valid ? handleSubmit() : null}
                    style={{ background: "none", border: "none" }}
                >
                    {
                        valid ?
                            <SubmitIcon
                                className="functionalButton mr-auto d-block"
                                style={{ minWidth: 140, maxWidth: 140 }}
                            />
                            :
                            <SubmitIconNosel
                                className="functionalButton mr-auto d-block"
                                style={{ minWidth: 140, maxWidth: 140 }}
                            />
                    }
                </button>
            </div>
        </div>
    )
}

export default DuplicateForm

{/*  */ }
