import React from "react";
import { withTranslation } from "react-i18next";
import { Col, Row } from "shards-react";
import { ReactComponent as AddRecognitionIcon } from "../../images/add_a_recognition.svg";
import { fetchRecognizeOpportunityById } from "../../services/recognizeService";
import RecognitionItem from "./RecognitionItem";

class RecognitionForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedEmployees: [],
      recognitionItems: [], // Array to store added recognition items
      options: [],
      finishedRecognize: false,
      data: {
        proponentName: "",
        teamMembers: [],
        implementationOwner: "",
        implementationCoach: [],
      },
      opportunityId: props.ideaItem.id, // Placeholder for now, can be passed via props
      newRecognition: []
    };

    this.selectEmployees = this.selectEmployees.bind(this);
    this.addRecognitionItem = this.addRecognitionItem.bind(this);
    this.removeRecognitionItem = this.removeRecognitionItem.bind(this);
    this.updateItems = this.updateItems.bind(this);
  }

  componentDidMount() {
    this.fetchOpportunityData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.recognitionItems.length !== this.state.recognitionItems.length) {
      this.props.setNextEnabled(this.state.recognitionItems.length > 0);
    }
  }

  updateItems() {
    this.fetchOpportunityData();
  }

  async fetchOpportunityData() {
    const { opportunityId } = this.state;

    try {
      const data = await fetchRecognizeOpportunityById(opportunityId);
      this.setState({
        data: {
          proponentName: data.proponent || "Unknown",
          teamMembers: data.teamMembers || [],
          implementationOwner: data.implementationLeader || "Unknown",
          implementationCoach: data.coaches || "Unknown",
        },
        finishedRecognize: data.recognition ? data.recognition.recognizedAt : false,
        recognitionItems: data.recognition.items ? data.recognition.items.map((item) => ({
          ...item,
          recognitionId: item.id,
        })) || [] : [],
        options: data.teamMembers.map((member, index) => ({ value: index + 1, label: member })) || [],
      });
      this.setState({ newRecognition: [] });

    } catch (error) {
      console.error("Error fetching opportunity data:", error);
    }
  }

  // Function to add a new recognition item
  addRecognitionItem() {
    const { recognitionItems } = this.state;

    // Limit to 4 recognition items
    if (recognitionItems.length + this.state.newRecognition.length < 4) {

      this.setState({ newRecognition: [recognitionItems.length + this.state.newRecognition.length + 1, ...this.state.newRecognition] });
    }
  }

  // Function to remove a recognition item
  removeRecognitionItem(id) {
    this.setState((prevState) => ({
      recognitionItems: prevState.recognitionItems.filter((item) => item.recognitionId !== id),
    }));
  }

  removeNewRecognitionItem(id) {    
    this.setState((prevState) => ({
      newRecognition: prevState.newRecognition.filter((item) => item !== id),
    }));
  }

  selectEmployees(selectedEmployees) {
    this.setState({ selectedEmployees });
  }

  render() {
    const { t } = this.props;
    const { data, recognitionItems } = this.state;

    const reversed = [...recognitionItems].reverse()    

    return (
      <div className="recognition-form">
        {/* Header for Employees Involved */}
        <Row form className="my-4">
          <Col md="12">
            <label><strong>{t("Employees Involved with this Opportunity")}</strong></label>
          </Col>
        </Row>

        {/* Proponent and Team Members */}
        <Row form>
          <Col md="12" className="form-group">
            <label><strong>{t("Proponent & Team Members")}</strong></label>
            <h6 style={{ color: "#000" }}>
              <strong>{data.proponentName}</strong> {data.teamMembers.join(" ▪ ")}
            </h6>
          </Col>
        </Row>

        {/* Implementation Owner and Coach */}
        <Row form>
          <Col md="3" className="form-group">
            <label><strong>{"Implementation Owner:"}</strong></label>
            <h6 style={{ color: "#000" }}><strong>{data.implementationOwner}</strong></h6>
          </Col>
          <Col md="3" className="form-group">
            <label><strong>{"Implementation Coach(es):"}</strong></label>
            {
              Array.isArray(data.implementationCoach) && data.implementationCoach.length > 0 ? (
                <h6 style={{ color: "#000" }}>
                  {data.implementationCoach.join(" ▪ ")}
                </h6>
              ) : (
                <h6 style={{ color: "#000" }}>
                  N/A
                </h6>
              )
            }
          </Col>
          {/* <Col md="3" className="form-group">
            <Button pill className="merged-opportunity-btn" style={{ backgroundColor: "#6245D9" }}>
              {t("Merged Opportunity")}
            </Button>
          </Col> */}
        </Row>

        {recognitionItems.length < 4 && (
          <Row form className="mt-4">
            <AddRecognitionIcon
              onClick={this.addRecognitionItem}
              className="functionalButton functionalButton ml-1 mr-4 d-block mb-4"
              style={{ minWidth: 180, maxWidth: 180, overflow: "visible", cursor: "pointer" }}
            />
          </Row>
        )}

        {
          this.state.newRecognition.length > 0 &&
          this.state.newRecognition.map((rec) => {
            return (
              <RecognitionItem
              toggleModal={this.props.toggleModal.bind(this)}
                updateItems={this.updateItems} 
                key={`new-${rec - 1}`}
                recognition={{
                  recognitionId: rec,
                  name: { en: `Recognition ${rec}`, es: `Reconocimiento ${rec}` },
                  active: true,
                }}
                opportunityId={this.props.ideaItem.id}
                removeRecognitionItem={() => this.removeNewRecognitionItem(rec)}
                // dismissModal={() => {
                //   this.props.dismissModal();
                //   this.setState({ newRecognition: false });
                // }}
                finishedRecognize={this.state.finishedRecognize}
              />
            )
          })
        }

        {/* Display recognition items */}
        {reversed.map((recognition) => (
          <Row form key={recognition.recognitionId} className="mt-4">
            <Col md="12">
              <RecognitionItem
              toggleModal={this.props.toggleModal.bind(this)}
                updateItems={this.updateItems} 
                key={`item-${recognition.recognitionId}`}
                recognition={recognition}
                opportunityId={this.props.ideaItem.id}
                removeRecognitionItem={() => this.removeRecognitionItem(recognition.recognitionId)}
                dismissModal={() => {
                  this.props.dismissModal();
                  this.setState({ newRecognition: false });
                }}
                finishedRecognize={this.state.finishedRecognize}
              />
            </Col>
          </Row>
        ))}
      </div>
    );
  }
}

export default withTranslation()(RecognitionForm);
