import axios from 'axios';
import { authUser } from './userService';
let cachedCategories = null;
let cacheExpirationTime = null;

const API_URL = process.env.REACT_APP_EP_API;

export const fetchTypes = async () => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;
  const authHeader = { 'Authorization': `Bearer ${accessToken}` };

  try {
    const response = await axios.get(`${API_URL}/table/types`, {
      headers: authHeader
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching categories:', error.response ? error.response.data : error.message);
    throw error;
  }
};


export const fetchAllCategories = async (inProgress = false) => {
  const loggedUser = authUser();
  const userId = loggedUser.id;
  const accessToken = loggedUser.accessToken;
  const authHeader = { 'Authorization': `Bearer ${accessToken}` };
  const cacheKey = `categories_${userId}`+(inProgress?'_inProgress':'');
  const cachedData = localStorage.getItem(cacheKey);

  if (cachedData) {
    const parsedData = JSON.parse(cachedData);
    refreshCategories(authHeader, cacheKey, inProgress);
    
    return parsedData;
  } else {
    return await refreshCategories(authHeader, cacheKey, inProgress);
  }
};

const refreshCategories = async (authHeader, cacheKey, inProgress=false) => {
  try {
    const response = await axios.get(`${API_URL}/table/categories?inProgress=${inProgress}`, {
      headers: authHeader
    });

    localStorage.setItem(cacheKey, JSON.stringify(response.data));

    return response.data;
  } catch (error) {
    console.error('Error fetching categories:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const fetchDepartments = async () => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;
  const authHeader = { 'Authorization': `Bearer ${accessToken}` };

  try {
    const response = await axios.get(`${API_URL}/table/departments`, {
      headers: authHeader
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching departments:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const fetchQuestions = async () => {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;
    const authHeader = { 'Authorization': `Bearer ${accessToken}` };
  
    try {
      const response = await axios.get(`${API_URL}/table/questions`, {
        headers: authHeader
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching questions:', error.response ? error.response.data : error.message);
      throw error;
    }
  };

  export const fetchTeam = async () => {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;
    const authHeader = { 'Authorization': `Bearer ${accessToken}` };
  
    try {
      const response = await axios.get(`${API_URL}/table/team`, {
        headers: authHeader
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching team:', error.response ? error.response.data : error.message);
      throw error;
    }
  };
  
  export const fetchCommittees = async () => {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;
    const authHeader = { 'Authorization': `Bearer ${accessToken}` };

    try {
        const response = await axios.get(`${API_URL}/table/committees`, {
            headers: {
                ...authHeader,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching committees:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const fetchUserRoles = async () => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;
  const authHeader = { 'Authorization': `Bearer ${accessToken}` };

  try {
    const response = await axios.get(`${API_URL}/table/user/roles`, {
      headers: authHeader
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching user roles:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const fetchUserPrivileges = async () => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;
  const authHeader = { 'Authorization': `Bearer ${accessToken}` };

  try {
    const response = await axios.get(`${API_URL}/table/user/privileges`, {
      headers: authHeader
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching user privileges:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const fetchUserSupervisors = async () => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;
  const authHeader = { 'Authorization': `Bearer ${accessToken}` };

  try {
    const response = await axios.get(`${API_URL}/table/user/supervisors`, {
      headers: authHeader
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching user supervisors:', error.response ? error.response.data : error.message);
    throw error;
  }
};