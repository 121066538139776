import axios from 'axios';
import { authUser } from './userService';

const API_URL = process.env.REACT_APP_EP_API;

const getUsers = async () => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;

  try {
    const response = await axios.get(`${API_URL}/administration/users`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error.response ? error.response.data : error.message);
    throw error;
  }
};

const exportUsers = async () => {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;
  
    try {
      const response = await axios.get(`${API_URL}/administration/users?action=export`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });
      return response.data; // You can handle the blob (file) appropriately here
    } catch (error) {
      console.error('Error exporting users:', error.response ? error.response.data : error.message);
      throw error;
    }
  };

  // Create a new user with form data
const createUser = async (userData) => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;
  
  const formData = new FormData();
  formData.append('firstName', userData.firstName);
  formData.append('lastName', userData.lastName);
  formData.append('email', userData.email);
  if(userData.departmentId) formData.append('departmentId', userData.departmentId);
  if(userData.supervisorId) formData.append('supervisorId', userData.supervisorId);
  if(userData.secondaryEmail) formData.append('secondaryEmail', userData.secondaryEmail);

  if (Array.isArray(userData.rolesIds)) {
    userData.rolesIds.forEach(roleId => formData.append('rolesIds', roleId));
  } else {
    formData.append('rolesIds', []);
  }

  if (Array.isArray(userData.privilegesIds)) {
    userData.privilegesIds.forEach(privilegeId => formData.append('privilegesIds', privilegeId));
  } else {
    formData.append('privilegesIds', []);
  }
  try {
    const response = await axios.post(`${API_URL}/administration/users`, formData, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error creating user:', error.response ? error.response.data : error.message);
    throw error;
  }
};


const updateUser = async (userData) => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;
  // Create a new FormData object
  const formData = new FormData();

  if (userData.userId) formData.append('userId', userData.userId);
  if (userData.firstName) formData.append('firstName', userData.firstName);
  if (userData.lastName) formData.append('lastName', userData.lastName);
  if (userData.email) formData.append('email', userData.email);
  if (userData.departmentId) formData.append('departmentId', userData.departmentId);
  if (userData.supervisorId) formData.append('supervisorId', userData.supervisorId);
  if (userData.secondaryEmail) formData.append('secondaryEmail', userData.secondaryEmail);

  // Assuming rolesIds is an array, we'll append each role to form data
  if (Array.isArray(userData.rolesIds)) {
    userData.rolesIds.forEach(roleId => formData.append('rolesIds', roleId));
  } else {
    formData.append('rolesIds', []);
  }

  if (Array.isArray(userData.privilegesIds)) {
    userData.privilegesIds.forEach(privilegeId => formData.append('privilegesIds', privilegeId));
  } else {
    formData.append('privilegesIds', []);
  }

  try {
    const response = await axios.patch(`${API_URL}/administration/users`, formData, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error updating user:', error.response ? error.response.data : error.message);
    throw error;
  }
};


const deleteUser = async (userId, assignments) => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;

  const formData = new FormData();
  formData.append('userId', userId);

  assignments.forEach((assignment) => {
    const { item, res, type, role } = assignment;

    switch (type) {
      case 'Opportunity':
          if (role === 'Proponent') 
            formData.append(`opportunityProponent[${item.id}]`, res.value);
          else if (role === 'Responsible') 
            formData.append(`opportunityResponsible[${item.id}]`, res.value);
          else if (role === 'Coach') 
            formData.append(`coach[${item.id}]`, res.value);
        break;
      case 'Committee':
        formData.append(`evaluationCommittee[${item.id}]`, res.value);
        break;
      case 'Department':
        formData.append(`department[${item.id}]`, res.value);
        break;
      default:
        console.warn(`Unknown assignment type: ${type}`);
        break;
    }
  });

  try {
    const response = await axios({
      method: 'delete',
      url: `${API_URL}/administration/users`,
      data: formData,
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};


const getUserAssignments = async (userId) => {
  const loggedUser = authUser(); // Assuming you have an authUser() function
  const accessToken = loggedUser.accessToken;

  try {
    const response = await axios.get(`${API_URL}/administration/users/${userId}/assignments`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      }
    });
    
    return response.data;
  } catch (error) {
    console.error('Error fetching user assignments:', error.response ? error.response.data : error.message);
    throw error;
  }
};

// GET all departments
const getDepartments = async () => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;

  try {
    const response = await axios.get(`${API_URL}/administration/departments`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching departments:', error.response ? error.response.data : error.message);
    throw error;
  }
};

// POST create a new department
const createDepartment = async (departmentData) => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;

  const formData = new FormData();
  formData.append('departmentId', departmentData.departmentId);
  if (departmentData.icon) formData.append('icon', departmentData.icon);
  if (departmentData.icon) formData.append('icon', departmentData.icon);

  
  if (departmentData.name) {
    if (departmentData.name.es) formData.append('name[es]', departmentData.name.es);
    if (departmentData.name.en) formData.append('name[en]', departmentData.name.en);
  }

  
  if (departmentData.description) {
    if (departmentData.description.es) formData.append('description[es]', departmentData.description.es);
    if (departmentData.description.en) formData.append('description[en]', departmentData.description.en);
  }

  if (departmentData.departmentLeader) formData.append('leaderId', departmentData.departmentLeader.id);
  if (departmentData.active) formData.append('active', departmentData.active);

  try {
    const response = await axios.post(`${API_URL}/administration/departments`, formData, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error creating department:', error.response ? error.response.data : error.message);
    throw error;
  }
};

// PATCH update an existing department
const updateDepartment = async (departmentData) => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;
  const formData = new FormData();
  formData.append('departmentId', departmentData.departmentId);
  if (departmentData.icon) formData.append('icon', departmentData.icon);

  
  if (departmentData.name) {
    if (departmentData.name.es) formData.append('name[es]', departmentData.name.es);
    if (departmentData.name.en) formData.append('name[en]', departmentData.name.en);
  }

  
  if (departmentData.description) {
    if (departmentData.description.es) formData.append('description[es]', departmentData.description.es);
    if (departmentData.description.en) formData.append('description[en]', departmentData.description.en);
  }

  if (departmentData.departmentLeader) formData.append('leaderId', departmentData.departmentLeader.id);
  if (departmentData.active) formData.append('active', departmentData.active);


  try {
    const response = await axios.patch(`${API_URL}/administration/departments`, formData, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
   
    console.error('Error updating department:', error.message ? error.response.data : error.message);
    throw error;
  }
};

// DELETE a department
const deleteDepartment = async (departmentId) => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;

  const formData = new FormData();
  formData.append('departmentId', departmentId);

  try {
    const response = await axios({
      method: 'delete',
      url: `${API_URL}/administration/departments`,
      data: formData,
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting department:', error.response ? error.response.data : error.message);
    throw error;
  }
};

const exportDepartments = async () => {
  const loggedUser = authUser(); 
  const accessToken = loggedUser.accessToken; 

  try {
    const response = await axios.get(`${API_URL}/administration/departments?action=export`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`, 
        'Content-Type': 'application/json',
      },
      responseType: 'blob' 
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'departments_export.csv'); 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    return response.data; 
  } catch (error) {
    console.error('Error exporting departments:', error.response ? error.response.data : error.message);
    throw error;
  }
};

// Export committee data
const exportCommittees = async () => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;

  try {
    const response = await axios.get(`${API_URL}/administration/committees?action=export`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'committees_export.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    return response.data;
  } catch (error) {
    console.error('Error exporting committees:', error.response ? error.response.data : error.message);
    throw error;
  }
};

// GET all committees
const getCommittees = async () => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;

  try {
    const response = await axios.get(`${API_URL}/administration/committees`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching committees:', error.response ? error.response.data : error.message);
    throw error;
  }
};

// DELETE a committee
const deleteCommittee = async (committeeId) => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;

  const formData = new FormData();
  formData.append('committeeId', committeeId);

  try {
    const response = await axios({
      method: 'delete',
      url: `${API_URL}/administration/committees`,
      data: formData,
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting committee:', error.response ? error.response.data : error.message);
    throw error;
  }
};

const createCommittee = async (committeeData) => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;

  const formData = new FormData();
  
  // Append form fields from the screenshot
  formData.append('name', committeeData.name);  // Example: 'Test'
  formData.append('transferCommittee', committeeData.transferCommittee);  // Example: 'false'
  formData.append('leaderId', committeeData.leaderId);  // Example: 'uXdya5FmBb'

  if (Array.isArray(committeeData.membersIds)) {
    committeeData.membersIds.forEach(memberId => formData.append('membersIds', memberId));
  }
  
  if (Array.isArray(committeeData.categoriesIds)) {
    committeeData.categoriesIds.forEach(categoryId => formData.append('categoriesIds', categoryId));
  }

  if (Array.isArray(committeeData.departmentsIds)) {
    committeeData.departmentsIds.forEach(departmentId => formData.append('departmentsIds', departmentId));
  }

  if (Array.isArray(committeeData.typesIds)) {
    committeeData.typesIds.forEach(typeId => formData.append('typesIds', typeId));
  }

  try {
    const response = await axios.post(`${API_URL}/administration/committees`, formData, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error creating committee:', error.response ? error.response.data : error.message);
    throw error;
  }
};

const updateCommittee = async (committeeData) => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;

  const formData = new FormData();
  
  // Append form fields, including committeeId
  formData.append('committeeId', committeeData.committeeId);  // Add committeeId to form data
  formData.append('name', committeeData.name);
  formData.append('transferCommittee', committeeData.transferCommittee);
  formData.append('leaderId', committeeData.leaderId);

  if (Array.isArray(committeeData.membersIds)) {
    committeeData.membersIds.forEach(memberId => formData.append('membersIds', memberId));
  }
  
  if (Array.isArray(committeeData.categoriesIds)) {
    committeeData.categoriesIds.forEach(categoryId => formData.append('categoriesIds', categoryId));
  }

  if (Array.isArray(committeeData.departmentsIds)) {
    committeeData.departmentsIds.forEach(departmentId => formData.append('departmentsIds', departmentId));
  }

  if (Array.isArray(committeeData.typesIds)) {
    committeeData.typesIds.forEach(typeId => formData.append('typesIds', typeId));
  }

  try {
    const apiUrl = `${API_URL}/administration/committees`;  // URL without committeeId in the path

    const response = await axios.patch(apiUrl, formData, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    
    return response.data;
  } catch (error) {
    console.error('Error updating committee:', error.response ? error.response.data : error.message);
    throw error;
  }
};



export default {
  getUsers,
  exportUsers,
  createUser,
  updateUser,
  deleteUser,
  getUserAssignments,
  getDepartments,
  createDepartment,
  updateDepartment,
  deleteDepartment,
  exportDepartments,
  getCommittees,
  deleteCommittee,
  exportCommittees,
  createCommittee,
  updateCommittee
};
