import React, { Component } from "react";

import Parse from 'parse';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import { fetchAllCategories, fetchCommittees, fetchDepartments, fetchTypes } from "../../services/tableService";

class IdeaFilterManager extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            /* initial state */ 
            data:[],
            departments: [],
            department:[],
            statusData:[],
            newItem:'',
            searchString:'',
            category:[],
            status:'',
            ideaType: [],
            ideaQuestions:[],
            deletedCategoryData: [],
            evalComms: [],
            evalComm: '',
            userComms: [],
            transferCommittee: false,
            categories: [],
            types: [],
            committess: []
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.handleTransferCommitteeChange = this.handleTransferCommitteeChange.bind(this);
        this.handleEvalCommChange = this.handleEvalCommChange.bind(this);
      }

      handleChange(event) {
        this.setState({newItem: event.target.value});
      }


      componentDidMount() {
        // this.fetchNewData("IdeaCategory");
        // this.fetchNewData("IdeaDepartment");
        // this.fetchNewData("DeletedIdeaCategory")
        // this.fetchNewData("IdeaStatus");
        // this.fetchQuestions();
        // this.getUserComms();
        this.fetchFilterData();
        this.handleEvalCommChange( { value: 'All', label: 'All' })
      }

      componentDidUpdate(prevProps, prevState) {
        if (prevProps.reset !== this.props.reset) {
          if (this.props.reset == true) {
            this.setState({evalComm: '', ideaType: '', department: '', category: ''});
          }
        }
      }

      fetchFilterData = async() => {
        const categories = await fetchAllCategories();
        const departments = await fetchDepartments();
        const types = await fetchTypes();
        const committess = await fetchCommittees();
       
        this.setState({
          categories,
          departments,
          types,
          committess
        });

      }
      

      getUserComms = async() => {
        // const user = Parse.User.current()
        // var query = new Parse.Query(Parse.User);
        // query.equalTo("objectId", user.id);
        // const results = await query.find();
        // const committees = results[0].get("committees")
        
        this.setState({userComms: []}, () => {
          this.fetchNewData("EvaluationCommittee")
        })
      }

      fetchQuestions() {
        const className = "IdeaQuestion";
  
        var ItemClass = Parse.Object.extend(className);
        var query = new Parse.Query(ItemClass);
        query.equalTo("field", false);
        query.find()
        .then((results) => {
          
            this.setState({
                ideaQuestions: results
            });
            
        }, (error) => {
            this.setState({
                ideaQuestions: []
            });
          // The object was not retrieved successfully.
          // error is a Parse.Error with an error code and message.
        });
      }

      fetchNewData(myClassName) {
        const className = myClassName;

        var ItemClass = Parse.Object.extend(className);
        var query = new Parse.Query(ItemClass);

        if (myClassName == "IdeaCategory" || myClassName == "DeletedIdeaCategory" || myClassName == "IdeaDepartment" || myClassName== "EvaluationCommittee") {
            query.findAll()
            .then((results) => {
            
            if (myClassName == 'DeletedIdeaCategory') {
              this.setState({
                deletedCategoryData: results
              });
            } else if (myClassName == 'IdeaDepartment'){
              this.setState({
                departments: results
              });
            } else if (myClassName == 'EvaluationCommittee'){
              const user = Parse.User.current()
              if(results) {
                const filteredCommittees = results.filter(committee => {
                  const members = committee.get('members');
                  if (members) {
                    const userInCommittee = members.some(member => member && member.value.id === user.id);
                    if (userInCommittee) return true;
                  }

                  if(committee.get('committeeLeader') === user.get('username')) return true;

                  return false;
                });

                if(filteredCommittees){
                  this.setState({
                    evalComms: filteredCommittees
                  });
                }else{
                  this.setState({
                    evalComms: []
                  });
                }
              }else{
                this.setState({
                  evalComms: []
                });
              }
            }
            else {
              this.setState({
                data: results
              });
            }
               
            }, (error) => {
                this.setState({
                    data: []
                });
            // The object was not retrieved successfully.
            // error is a Parse.Error with an error code and message.
            });
        } else {
            query.find()
            .then((results) => {
            
                this.setState({
                    statusData: results
                });
            }, (error) => {
                this.setState({
                    statusData: []
                });
            // The object was not retrieved successfully.
            // error is a Parse.Error with an error code and message.
            });
        }
      }

      handleSubmit(event) {
        const className = this.props.className;
        const PropertyItem = Parse.Object.extend(className);
        const newItem= new PropertyItem();
        
        newItem.set("itemName", this.state.newItem);

        if (className == "IdeaCategory") {
          newItem.set("show", false);
        }
        newItem.set("customUpdatedAt", new Date());
        newItem.save()
        .then((item) => {
        // Execute any logic that should take place after the object is saved.
          this.fetchNewData();
          alert('¡La operacion fue exitosa!');
        }, (error) => {
          // Execute any logic that should take place if the save fails.
          // error is a Parse.Error with an error code and message.
          alert('Hubo un error en la operacion: ' + error.message);
        });
      }

      handleToggle(item) {
        const className = this.props.className;
        
        if (className == "IdeaCategory" || className == "IdeaDepartment") {
          
          const isShowing = item.get("show");
          item.set("show", !isShowing);
          item.set("customUpdatedAt", new Date());
          item.save().then((item) => {
            // Now let's update it with some new data. In this case, only cheatMode and score
            // will get sent to the cloud. playerName hasn't changed.
            this.fetchNewData();
          });
        } 
      }

      deleteItem(item) {
        item.destroy({})
        .then((item) => {
        // Execute any logic that should take place after the object is saved.
          this.fetchNewData();
          alert('¡La operacion fue exitosa!');
        }, (error) => {
          // Execute any logic that should take place if the save fails.
          // error is a Parse.Error with an error code and message.
          alert('Hubo un error en la operacion: ' + error.message);
        });
      }

      handleCategoryChange(event) {
          const newCat = event.label;
          this.props.onCategoryChange(newCat);
       this.props.onIsTransferredChange(false)
          this.setState({
            isTransferred: false,
            category: newCat
        });
      }

      handleMultiCategoryChange(event) {
        const newCat = event;
        this.props.onCategoryChange(newCat);
        this.props.onIsTransferredChange(false)
        this.setState({
          isTransferred: false,
          evalComm: { value: 'All', label: 'All' },
          category: newCat
      });
    }
      
      handleDepartmentChange(event) {
        this.handleTransferCommitteeChange(false)
        
        const newDept = event.label;
        this.props.onDepartmentChange(newDept);
        this.props.onIsTransferredChange(false)
        this.setState({
          isTransferred: false,
          department: newDept
      });
    }

    handleMultiDepartmentChange(event) {
      const newDept = event;
      this.props.onDepartmentChange(newDept);
      this.props.onIsTransferredChange(false)
      this.setState({
        isTransferred: false,
        evalComm: { value: 'All', label: 'All' },
        department: newDept
    });
  }

  handleEvalCommChange(selectedOptions) {
    let newEvalComm = selectedOptions === null || selectedOptions.value.length === 0 ? 'All' : selectedOptions.value;
    let myDepartments = null;
    let myCategories = null;
    let myTypes = null;
    let isTransferCommittee = false;
    if (newEvalComm !== 'All') {
       
        if (newEvalComm.departmentsIds && Array.isArray(newEvalComm.departmentsIds)) {
            myDepartments = newEvalComm.departmentsIds.includes('All Departments')
                ? null
                : newEvalComm.departmentsIds.map((item) => {
                    var department = this.state.departments.find(function(dept) {
                        return dept.id === item;
                    });
                
                    if (department) {
                        return {
                            value: item,
                            label: department.transcription[localStorage.getItem('language') || 'en'] || item
                        };
                    }
                    return null; 
                }).filter(Boolean); 
        }

       
        if (newEvalComm.categoriesIds && Array.isArray(newEvalComm.categoriesIds)) {
            myCategories = newEvalComm.categoriesIds.includes('All Categories')
                ? null
                : newEvalComm.categoriesIds.map((item) => {
                    var category = this.state.categories.find(function(cat) {
                        return cat.id === item;
                    });
                    
                    if (category) {
                        return {
                            value: item,
                            label: category.transcription[localStorage.getItem('language') || 'en'] || item
                        };
                    }
                    return null; 
                }).filter(Boolean); 
        }

       
        if (newEvalComm.typesIds && Array.isArray(newEvalComm.typesIds)) {
            myTypes = newEvalComm.typesIds.includes('All Types') || 
                      (newEvalComm.categoriesIds && newEvalComm.categoriesIds.includes('All Categories'))
                ? null
                : newEvalComm.typesIds.map((item) => {
                    var value = '';
                    switch (item) {
                        case 'problem-solving':
                            value = 'problem-solving';
                            break;
                        case 'innovation':
                            value = 'innovation';
                            break;
                        case 'continuous-improvement':
                            value = 'continuous-improvement';
                            break;
                        default:
                            value = item.toLowerCase().replace(/ /g, '-');
                    }
                    var type = this.state.types.find(function(type) {
                        return type.id === value;
                    });
                  
                    if (type) {
                        return {
                            value: value,
                            label: type.title.en || item
                        };
                    }
                    return null; 
                }).filter(Boolean); 
        }


        isTransferCommittee = newEvalComm.transferCommittee === true;
    }

    this.props.onDepartmentChange(myDepartments);
    this.props.onCategoryChange(myCategories);
    this.props.onTypeChange(myTypes);
    this.props.onEvalCommChange(newEvalComm);
    this.props.onIsTransferredChange(isTransferCommittee);


    this.setState({
        evalComm: selectedOptions,
        department: myDepartments,
        category: myCategories,
        committeesIds: newEvalComm.id,
        isTransferred: isTransferCommittee,
        ideaType: myTypes,
    }, () => {
        // var filters = {
        //     categoriesIds: myCategories ? myCategories.map(function(c) { return c.value; }).join(',') : null,
        //     typesIds: myTypes ? myTypes.map(function(t) { return t.value; }).join(',') : null,
        //     departmentsIds: myDepartments ? myDepartments.map(function(d) { return d.value; }).join(',') : null,
        //     committeesIds: newEvalComm.id,
        //     isTransferred: this.state.transferCommittee
        // };
        // console.log(filters);

        // getOpportunitiesWithFilters(filters)
        //     .then(function(filteredData) {
        //         this.setState({ filteredData: filteredData, ideas: filteredData });
        //     }.bind(this))
        //     .catch(function(error) {
        //         console.error('Error fetching filtered opportunities:', error);
        //     });
    });
  }
  
  
  

      handleStatusChange(event) {
   

        var newStat = event.target.value == 'Sometidas'?'SOMETIDA':event.target.value;

        this.props.onStatusChange(newStat);
        this.setState({
          status: newStat,
      });
    }

    handleTypeChange(event) {
      var newType = event.label == 'Todas'?'Todas':event.label;
      this.props.onIsTransferredChange(false)
      this.props.onTypeChange(newType.toLowerCase());
      this.setState({
        ideaType: newType,
        isTransferred: false
    });
    }

    handleMultiTypeChange(event) {
      this.props.onIsTransferredChange(false)
      this.props.onTypeChange(event);
      this.setState({
        ideaType: event,
        isTransferred: false
      });
    }
    
    handleTransferCommitteeChange(newStatus) {
      this.props.onIsTransferredChange(newStatus);
      this.props.onDepartmentChange(null);
      this.props.onCategoryChange(null);
      this.props.onTypeChange(null);
      this.props.onEvalCommChange('All');
      
      this.setState({ 
        isTransferred: newStatus,
        evalComm: { value: 'All', label: 'All' },
        department: null,
        category: null,
        committeesIds: null,
        ideaType: null
      }, () => {
      });
    }

    handleQuestionChange(event) {
      var newType = event.target.value == 'Todas'?'Todas':event.target.value;
      this.props.onQuestionChange(newType);
    }


      render() {
          const { category, ideaType, categories, departments, types, committess } = this.state;
          const { t } = this.props;
          const storageLanguage =  localStorage.getItem('language') != null?localStorage.getItem('language'):'en';

          return(
            <Card small className="mb-3">
                <CardHeader className="border-bottom">
                <label htmlFor="feInputState">{t("IDEA_MANAGE_COM")}</label>
                  <Select
                
                    options={[
                      { value: 'All', label: 'All' },
                      ...committess.map((item, idx) => ({
                        value: item,
                        label: item.title
                      }))
                    ]}
                    onChange={this.handleEvalCommChange.bind(this)}
                    value={this.state.evalComm}
                    placeholder="Select evaluation committee..."
                    style={{ zIndex: 1000, position: 'relative' }} // Set your desired z-index value
                  />
                </CardHeader>
                <CardBody className="p-0">
                <ListGroup flush>
                <ListGroupItem className="p-3">
                    <Row>
                    <Col>
                        <Form>
                        <Row form>
                                <Col md="12" className="form-group">
                                <label htmlFor="feInputState" >{t("IDEA_MANAGE_CATS")}</label>
                              
                                <Select
                                  isMulti
                                  options={[
                                    // { value: 'All Categories', label: 'All' },
                                    ...categories.map((item, idx) => ({
                                      value: item.id,
                                      label: item.transcription[storageLanguage]
                                    }))
                                    
                                  ]}
                                  onChange={this.handleMultiCategoryChange.bind(this)}
                                  value={category}
                                  placeholder={t("Select Categories")}
                                />
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col>
                        <Form>
                        <Row form>
                                <Col md="12" className="form-group">
                                <label htmlFor="feInputState">{t('IDEA_MANAGE_DEPTS')}</label>
                              
                                <Select
                                  isMulti
                                  options={[
                                    // { value: 'All Departments', label: 'All' },
                                    ...departments.map((item, idx) => ({
                                      value: item.id,
                                      label: item.transcription[storageLanguage]
                                    }))
                                  ]}
                                  onChange={this.handleMultiDepartmentChange.bind(this)}
                                  value={this.state.department}
                                  placeholder={t("Select Departments")}
                                />
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    
                    <Col>
                        <Form>
                        <label htmlFor="feInputState">{t("IDEA_MANAGE_TYPES")}</label>
                    
                                <Select
                                  isMulti
                                  id="feInputState"
                                  options={[
                                    // { value: 'All Departments', label: 'All' },
                                    ...types.map((item, idx) => ({
                                      value: item.id,
                                      label: item.title.en
                                    }))
                                  ]}
                                  onChange={this.handleMultiTypeChange.bind(this)}
                                  value={ideaType}
                                  placeholder={t("Select Type")}
                                />

                        </Form>
                    </Col>
                    
                    </Row>
                    <Row>
                    <Col>
                          <input  type="checkbox" value="" id="flexCheckDefault" checked={this.state.isTransferred} onChange={() => {
                            const newStatus = !this.state.isTransferred;
                            this.handleTransferCommitteeChange(newStatus);
                            }} className="checkbox"/>
                          <label for="flexCheckDefault">{t('Show transferred opportunities')}</label>
                    </Col>
                    </Row>
                </ListGroupItem>
                </ListGroup>
            </CardBody>
            </Card>
            
            );
      }
}


export default withTranslation()(IdeaFilterManager);