import Parse from 'parse';
import React from "react";
import {
    Row
} from "shards-react";

import "./LocalStyles/CategorySelectStyle.css";

// Translation
import { withTranslation } from 'react-i18next';

// Defult
import { ReactComponent as DollarImage } from "../../images/dollar-symbol.svg";
import { ReactComponent as HandImage } from "../../images/hand.svg";
import { ReactComponent as ChartImage } from "../../images/line-chart.svg";
import { ReactComponent as ShieldImage } from "../../images/shield.svg";
import { ReactComponent as TeamImage } from "../../images/team.svg";
import { ReactComponent as TimeImage } from "../../images/time.svg";
// fill="#157ffb"

// New
import { ReactComponent as CheckmarkImage } from "../../images/check1.svg";
import { ReactComponent as UrgentImage } from '../../images/Icons_Idle_01_Urgent.svg';
import { ReactComponent as ProductivityImage } from "../../images/Icons_Idle_02_Productivity.svg";
import { ReactComponent as TrophyImage } from "../../images/Icons_Idle_04_Trophy.svg";
import { ReactComponent as Shield2Image } from "../../images/Icons_Idle_05_Shield.svg";
import { ReactComponent as DollarSignImage } from "../../images/Icons_Idle_06_Dollar Sign.svg";
import { ReactComponent as NumberOneImage } from "../../images/Icons_Idle_07_Number One.svg";
import { ReactComponent as SelectIdeaImageRev } from "../../images/select_category_REV3.svg";

// New Selected
import { ReactComponent as CheckmarkImageSelected } from "../../images/check1_selected.svg";
import { ReactComponent as UrgentImageSelected } from '../../images/Icons_Selected_01_Urgent.svg';
import { ReactComponent as ProductivityImageSelected } from "../../images/Icons_Selected_02_Productivity.svg";
import { ReactComponent as TrophyImageSelected } from "../../images/Icons_Selected_04_Trophy.svg";
import { ReactComponent as Shield2ImageSelected } from "../../images/Icons_Selected_05_Shield.svg";
import { ReactComponent as DollarSignImageSelected } from "../../images/Icons_Selected_06_Dollar Sign.svg";
import { ReactComponent as NumberOneImageSelected } from "../../images/Icons_Selected_07_Number One.svg";

const selectedColor = '#FD902c'
const blueColor = '#3A7BBB'
const greenColor = '#7FA86F'
const goldColor = '#DDB153'
const purpleColor = '#62466B'

class CategorySelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectionValue: '',
            sq1Color: '',
            sq2Color: '',
            sq3Color: '',
            sq4Color: '',
            sq5Color: '',
            titleColor1: 'black',
            titleColor2: 'black',
            titleColor3: 'black',
            titleColor4: 'black',
            titleColor5: 'black',
            handIcon: 'black',
            shieldIcon: blueColor,
            chartIcon: greenColor,
            clockIcon: goldColor,
            extraIcon: purpleColor,
            data:[],
            showSecurity:'',
            showQuality:'',
            showProductivity:'',
            showAction:'',
            showExtra:'',
            extraName:'',
            extraValue: '',
            allCats: [],
            bgColor: ''
          }
          //this.fetchCategoryData = this.fetchCategoryData.bind(this);
    }

    // componentDidMount() {
    //     this.fetchCategoryData();
    // }

    fetchCategoryData() {
        const className = "IdeaCategory";
  
        var ItemClass = Parse.Object.extend(className);
        var query = new Parse.Query(ItemClass);
  
        query.find()
        .then((results) => {
            // this.setState({
            //     data: results
            // });
            this.setState({allCats: results})
             
            
            for (var i = 0; i < results.length; i++) {
                //  
                //  
                if (results[i].get("itemName") == "Seguridad") {
                    this.setState({
                        showSecurity: results[i].get("show")
                    });
                } else if (results[i].get("itemName") == "Calidad") {
                    this.setState({
                        showQuality: results[i].get("show")
                    });
                } else if (results[i].get("itemName") == "Productividad") {
                    this.setState({
                        showProductivity: results[i].get("show")
                    });
                } else if (results[i].get("itemName") == "Accion Inmediata") {
                    this.setState({
                        showAction: results[i].get("show")
                    });
                } else {
                    this.setState({
                        showExtra: results[i].get("show"),
                        extraName: results[i].get("itemNameTrans"),
                        extraValue: results[i].get("itemName")
                    });
                } 
              }
            //  
        }, (error) => {
            this.setState({
                data: []
            });
          // The object was not retrieved successfully.
          // error is a Parse.Error with an error code and message.
        });
      }

    changeSelectedValue(selectionValue, category) {
        const myExtraValue = category.get("itemName")
        this.setState({
            selectionValue: selectionValue
        })
        this.props.setCategory(myExtraValue);
    }

    getIcon(name, fillColor) {

        const newIcons = [
            {normal: <UrgentImage className="mr-auto d-block" style={{minWidth: 120, maxWidth:120}}/>,
             selected: <UrgentImageSelected className="mr-auto d-block" style={{minWidth: 120, maxWidth:120}}/>
            },
            {normal: <ProductivityImage className="mr-auto d-block" style={{minWidth: 120, maxWidth:120}}/>,
             selected: <ProductivityImageSelected className="mr-auto d-block" style={{minWidth: 120, maxWidth:120}}/>
            },
            {normal: <CheckmarkImage className="mr-auto d-block" style={{minWidth: 120, maxWidth:120}}/>,
             selected: <CheckmarkImageSelected className="mr-auto d-block" style={{minWidth: 120, maxWidth:120}}/>
            },
            {normal: <TrophyImage className="mr-auto d-block" style={{minWidth: 120, maxWidth:120}}/>,
             selected: <TrophyImageSelected className="mr-auto d-block" style={{minWidth: 120, maxWidth:120}}/>
            },
            {normal: <Shield2Image className="mr-auto d-block" style={{minWidth: 120, maxWidth:120}}/>,
             selected: <Shield2ImageSelected className="mr-auto d-block" style={{minWidth: 120, maxWidth:120}}/>
            },
            {normal: <DollarSignImage className="mr-auto d-block" style={{minWidth: 120, maxWidth:120}}/>,
             selected: <DollarSignImageSelected className="mr-auto d-block" style={{minWidth: 120, maxWidth:120}}/>
            },
            {normal: <NumberOneImage className="mr-auto d-block" style={{minWidth: 120, maxWidth:120}}/>,
             selected: <NumberOneImageSelected className="mr-auto d-block" style={{minWidth: 120, maxWidth:120}}/>
            },
        ]

        switch(name) {
            case 'HandImage':
              return <HandImage className="mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
            case 'ShieldImage':
                return <ShieldImage className="mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
            case 'ChartImage':
                return <ChartImage className="mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
            case 'TeamImage':
                return <TeamImage className="mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
            case 'DollarImage':
                return <DollarImage className="mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
            case 'ClockImage':
                return <TimeImage className="mx-auto d-block" style={{minWidth: 80, maxWidth:80, fill: fillColor}}/>;
            //New Icons
            case 'Urgent':
                return newIcons[0].selected;
            case 'Productivity':
                return newIcons[1].selected;
            case 'Checkmark':
                return newIcons[2].selected;
            case 'Trophy':
                return newIcons[3].selected;
            case 'Shield Image':
                return newIcons[4].selected;
            case 'Dollar':
                return newIcons[5].selected;
            case 'Dollar Sign':
                return newIcons[5].selected;
            case 'Number One':
                return newIcons[6].selected;
            default:
              return <SelectIdeaImageRev className="d-block" style={{width: 120}}/> //<img src={selectIdeaImage} width="200" height="200" />//<SelectIdeaImage className="mr-auto d-block" style={{minWidth: 200, maxWidth:200}}/>
          }
    }

    getBgColor(index) {
        if (this.state.selectionValue == index) {
            return selectedColor
        } else {
            return ''
        }
    }

    render() {
        const { titleColor1 } = this.state
        const { categoryName, categoryDescription, categoryItem } = this.props;
        const storageLanguage =  localStorage.getItem('language') != null ? localStorage.getItem('language') : 'en';
        const myDescription = categoryDescription[storageLanguage]
        return(
            <div className="">
                <Row className={'ml-0'}>
                <div className="square pt-2">
                    {this.getIcon(categoryName, 'Black')}
                    <div className="mr-auto mt-4" style={{width: '70%', backgrounColor: 'black'}}>
                        {categoryName && <p style={{color: titleColor1, textAlign: 'left', fontSize: 12, fontWeight: 600}}>{myDescription}</p>}
                    </div>
                    {/* Fix KPI */}
                    <div className="mr-auto mt-4" style={{width: '70%', backgrounColor: 'black'}}>
                        {categoryName && categoryItem.kpis.length > 0 && categoryItem.kpis.map((kpi, index) => {
                            return (<p key={index}>{kpi[storageLanguage] || ''}</p>)
                        })}
                    </div>
                </div>
                </Row>
            </div>
        )
    }
}

export default withTranslation()(CategorySelect);