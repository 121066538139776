import axios from 'axios';
import { authUser } from './userService';

const API_URL = process.env.REACT_APP_EP_API;

const getQuestions = async () => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;

  try {
    const response = await axios.get(`${API_URL}/administration/questions`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`, 
        'Content-Type': 'application/json',
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching categories:', error.response ? error.response.data : error.message);
    throw error;
  }

};


const deleteQuestions = async (questionId) => {
  try {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;

    const formData = new FormData();
    formData.append('questionId', questionId);

    const response = await axios.delete(`${API_URL}/administration/questions`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`, 
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    });

    // localStorage.removeItem(`categories_${loggedUser.id}`);
  
    return response.data;
  } catch (error) {
    console.error('Error fetching question ideas:', error.response ? error.response.data : error.message);
    throw error;
  }
};

const updateQuestions = async (questionId, questionData) => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;
  const authHeader = { 'Authorization': `Bearer ${accessToken}` };
  try {
      const formData = new FormData();
      formData.append('questionId', questionId);

      if (questionData.question && questionData.question.en) {
        formData.append('question[en]', questionData.question.en);
      }
      if (questionData.question && questionData.question.es) {
        formData.append('question[es]', questionData.question.es);
      }
      
      formData.append('required', questionData.required);
      
      if (questionData.questionType) {
        formData.append('questionType', questionData.questionType);
      }
      
      if (questionData.responseType) {
        formData.append('responseType', questionData.responseType);
      }
      if (questionData.categoryId) {
        formData.append('categoryId', questionData.categoryId);
      }
      if (questionData.typeId) {
        formData.append('typeId', questionData.typeId);
      }
      
      const response = await axios.patch(`${API_URL}/administration/questions`, formData, {
        headers: {
          'Authorization': `Bearer ${accessToken}`, 
          'Content-Type': 'multipart/form-data',
        },
      });
      
      return response.data;
  } catch (error) {
      let errorMessage = 'Error updating question.';
      
      if (error.response && error.response.data && Array.isArray(error.response.data.errors) && error.response.data.errors.length > 0) {
          errorMessage = error.response.data.errors[0];
      } else if (error.response && error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
      } else if (error.message) {
          errorMessage = error.message;
      }

      alert(errorMessage);
      console.error('Error updating question:', errorMessage);
      throw error;
  }
};

const createdQuestions = async (questionData) => {
  const loggedUser = authUser();
  const accessToken = loggedUser.accessToken;
  try {
    const formData = new FormData();
    if (questionData.question && questionData.question.en) {
      formData.append('question[en]', questionData.question.en);
    }
    if (questionData.question && questionData.question.es) {
      formData.append('question[es]', questionData.question.es);
    }

    formData.append('required', questionData.required);
    
    if (questionData.questionType) {
      formData.append('questionType', questionData.questionType);
    }
    
    if (questionData.responseType) {
      formData.append('responseType', questionData.responseType);
    }
    if (questionData.categoryId) {
      formData.append('categoryId', questionData.categoryId);
    }

    if (questionData.typeId) {
      formData.append('typeId', questionData.typeId);
    }
      
    const response = await axios.post(`${API_URL}/administration/questions`, formData, {
      headers: {
        'Authorization': `Bearer ${accessToken}`, 
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
        let errorMessage = 'Error creating question.';
        
        if (error.response && error.response.data && Array.isArray(error.response.data.errors) && error.response.data.errors.length > 0) {
            errorMessage = error.response.data.errors[0];
        } else if (error.response && error.response.data && error.response.data.message) {
            errorMessage = error.response.data.message;
        } else if (error.message) {
            errorMessage = error.message;
        }

        alert(errorMessage);
        console.error('Error creating question:', errorMessage);
        throw error;
  }
};

const downloadDataQuestions = async () => {
  try {
    const loggedUser = authUser();
    const accessToken = loggedUser.accessToken;
    const response = await axios.get(`${API_URL}/administration/questions`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`, 
        'Content-Type': 'application/json',
      },
      params: {
        action: 'export'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching opportunity ideas:', error.response ? error.response.data : error.message);
    throw error;
  }
};


export default {
  getQuestions,
  downloadDataQuestions,
  deleteQuestions,
  createdQuestions,
  updateQuestions
};