// export default withTranslation()(RecognitionForm);
import React from "react";
import { withTranslation } from "react-i18next";
import { Col, Row } from "shards-react";
import { fetchRecognizeOpportunityById } from "../../services/recognizeService";
import RecognitionItem from "./RecognitionItem";

class RecognitionView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedEmployees: [],
      recognitionItems: [], // Array to store added recognition items
      options: [],
      finishedRecognize: false,
      data: {
        proponentName: "",
        teamMembers: [],
        implementationOwner: "",
        implementationCoach: [],
      },
      opportunityId: props.ideaItem.id, // Placeholder for now, can be passed via props
    };

    this.selectEmployees = this.selectEmployees.bind(this);
    this.addRecognitionItem = this.addRecognitionItem.bind(this);
    this.removeRecognitionItem = this.removeRecognitionItem.bind(this);
  }

  componentDidMount() {
    this.fetchOpportunityData();
  }

  // Function to fetch recognition opportunity by ID
  async fetchOpportunityData() {
    const { opportunityId } = this.state;
    
    try {
      const data = await fetchRecognizeOpportunityById(opportunityId);

      this.setState({
        data: {
          proponentName: data.proponent || "N/A",
          teamMembers: data.teamMembers || [],
          implementationOwner: data.implementationLeader || "N/A",
          implementationCoach: data.coaches || "N/A",
        },
        finishedRecognize: data.recognition.recognizedAt || false,
        options: data.teamMembers.map((member, index) => ({ value: index + 1, label: member })) || [],
        recognitionItems: data.recognition.items || [],
      });
    } catch (error) {
      console.error("Error fetching opportunity data:", error);
    }
  }

  // Function to add a new recognition item
  addRecognitionItem() {
    const { recognitionItems } = this.state;

    // Limit to 4 recognition items
    if (recognitionItems.length < 4) {
      const newItem = {
        id: recognitionItems.length + 1,
        name: { en: `Recognition ${recognitionItems.length + 1}`, es: `Reconocimiento ${recognitionItems.length + 1}` },
        active: true,
      };
      this.setState({ recognitionItems: [...recognitionItems, newItem] });
    }
  }

  // Function to remove a recognition item
  removeRecognitionItem(id) {
  }
  
  // Function to remove a recognition item
  updateItems(id) {
      this.fetchOpportunityData();
  }

  selectEmployees(selectedEmployees) {
    this.setState({ selectedEmployees });
  }

  render() {
    const { t } = this.props;
    const { data, recognitionItems } = this.state;
    const proponentAndTeam = [data.proponentName, ...data.teamMembers]

    return (
      <div className="recognition-form">
        {/* Header for Employees Involved */}
        <Row form className="my-4">
          <Col md="12">
            <label><strong>{t("Employees Involved with this Opportunity")}</strong></label>
          </Col>
        </Row>

        {/* Proponent and Team Members */}
        <Row form>
          <Col md="12" className="form-group">
            <label><strong>{t("Proponent & Team Members")}</strong></label>
            <h6 style={{ color: "#000" }}>
              {proponentAndTeam.join(" ▪ ")}
            </h6>
          </Col>
        </Row>

        {/* Implementation Owner and Coach */}
        <Row form>
          <Col md="3" className="form-group">
            <label><strong>{"Implementation Owner:"}</strong></label>
            <h6 style={{ color: "#000" }}><strong>{data.implementationOwner}</strong></h6>
          </Col>
          <Col md="3" className="form-group">
            <label><strong>{"Implementation Coach(es):"}</strong></label>
            {
              Array.isArray(data.implementationCoach) && data.implementationCoach.length > 0 ? (
                <h6 style={{ color: "#000" }}>
                  {data.implementationCoach.join(" ▪ ")}
                </h6>
              ) : (
                <h6 style={{ color: "#000" }}>
                 N/A
                </h6>
              )
            }
          </Col>
          {/* <Col md="3" className="form-group">
            <Button pill className="merged-opportunity-btn" style={{ backgroundColor: "#6245D9" }}>
              {t("Merged Opportunity")}
            </Button>
          </Col> */}
        </Row>

        {/* Button/Icon to add recognition, only show if less than 4 items */}

        {/* Display recognition items */}
        {recognitionItems.map((recognition) => (
          <Row form key={recognition.id} className="mt-4">
            <Col md="12">
              <RecognitionItem
                recognition={recognition}
                opportunityId={this.props.ideaItem.id}
                removeRecognitionItem={this.removeRecognitionItem} 
                updateItems={this.updateItems} 
                dismissModal={this.props.dismissModal}
                finishedRecognize={this.state.finishedRecognize}
              />
            </Col>
          </Row>
        ))}
      </div>
    );
  }
}

export default withTranslation()(RecognitionView);
